import React, { useState, useEffect } from 'react'

import '../../assets/main.css';
import '../../assets/admin.css';

import Logo from '../../assets/logos/logo.png'


import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAddMarketWalletMutation, useGlobalSettngsQuery, useMarketAddRevokeMutation, useTeamAddRevokeMutation } from "../store/ReduxApis"
import Footer from "../Footer"
import {
    toast , Toaster
} from 'react-hot-toast';






function WalletTransactions() {

    const token = useGlobalSettngsQuery();
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [startTime, setStartTime] = useState("")

    const [value, onChange] = useState(new Date());
    const [loading, setLoading] = useState(false)
    
    const [createPost, responseInfo] = useTeamAddRevokeMutation();

    console.log(responseInfo);

   
    


    const min = token?.data?.data[0]?.min_contribution;
    const max = token?.data?.data[0]?.max_contribution;
    const bnb = token?.data?.data[0]?.referralBonus
    
    console.log(value)

    const timestamp = Date.parse(value);
    console.log(timestamp)

    const newPost = {
       
         
        "type": "reserve",
      "accounts": [
        account
    ]
    }

    const addWallet = (e) => {
        e.preventDefault()
        setLoading(true)
        createPost(newPost)

        console.log("'Given address is not a valid Ethereum address.", "800")
        console.log(`Given address ${account} is not a valid Ethereum address.`, "800")
    }

    useEffect(() => {
        if (responseInfo?.data?.status === "success") {
                toast("Succefully Added Reserve Revoke")  
                setLoading(false)
        } else if(responseInfo?.error?.data?.error === "Returned error: execution reverted: Reserve : user claimed all funds or user may not be added") {
            toast("User claimed all funds or user may not be added")  
            setLoading(false)
        } else if (responseInfo?.data?.status === "fail") {
            toast(`Token not added`)  
            setLoading(false)
        } else if (responseInfo?.error?.status === 500) {
            toast(`Please Try Valid Ethereum address`)  
            setLoading(false)
        } else if (responseInfo?.error?.data?.status === "fail") {
            toast(`${responseInfo?.error?.data?.message}`)  
            setLoading(false)
        } 
        
    }, [responseInfo])
    
    

  return (
      <div>
          <Toaster />
    <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
          <div class="row mb-2">
              <div class="col-sm-6 text">
                  <h1>Add Reserve Revoke Distribution Wallet</h1>
              </div>
              <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active">Reserve Revoke Distribution Wallet</li>
                  </ol>
              </div>
          </div>
      </div>
  </section>
<div class="row">
  <div class="col-md-8 offset-2">
      <div class="card  card-primary">
          <div class="card-header">
              <h3 class="card-title">Add Reserve Revoke Distribution Wallet</h3>
          </div>
          <form>
              <div class="card-body">
                  <div class="form-group">
                      <label className='label'> Account :</label>
                      <input  type="text" formControlName="network" onChange={(e) => setAccount(e.target.value)}
                          class="form-control input" />
  
                  </div>
                  {/* <div class="form-group">
                      <label className='label'> Amount :</label>
                      <input type="text input" formControlName="chainid" onChange={(e) => setAmount(e.target.value)}
                          class="form-control input" />
                  </div>
                  <div class="form-group">
                      <label className='label'> Start Time :</label>
                      <DateTimePicker onChange={onChange} value={value} />
                  </div>
   */}
              </div>
              
              <div class="card-footer">
                <button type="submit"  onClick={(e) => addWallet(e)}  className={loading === true ? "changed" : "changedColor"}
                    style={{height: "34px", width:"100px"}}>Submit</button>
            </div>
          </form>
      </div>
  </div>
</div>
<Footer></Footer>
</div>
</div>
   
  )
}

export default WalletTransactions;