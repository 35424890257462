import React from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logo.png'


import { Link } from 'react-router-dom';
import { useAdminSettingsQuery } from './store/ReduxApis';

import favIcon from "../assets/favicon.ico";






function Settings() {

    const settings = useAdminSettingsQuery();

    console.log(settings);


  return (

    <section className="content pages">

<div className='mainPage'>
         <h3>General Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
            
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
            </div>
            {/* <div className='icons'>
                      <i class="fas fa-bullhorn"></i>
          <li><Link className='link' to="/market">Market Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-key"></i>
          <li><Link className='link' to="/product">Product Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fab fa-dropbox"></i>
          <li><Link className='link' to="/airdrop">Airdrop Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-user-friends"></i>
          <li><Link className='link' to="/team">Team Wallet Status</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-anchor"></i>
          <li><Link className='link' to="/operation">Operation Wallet Status</Link></li>
                      </div> */}
                


          </div>
          </div>
         
          <div className="col-md-8" style={{width: "60%"}}>
        <div className="box box-info">
            <div className="box-header with-border">
                <h5 className="title">General Settings Form</h5>
            </div>

            <form action="#" method="post" className="form-horizontal" enctype="multipart/form-data"
                id="general_settings_form" >
                <input type="hidden" name="_token" value="EMMkkp66AdlzzVLPjUzVAeznIgefcQNijp284dNx" />

                
                <div className="box-body">


                    <div className="form-group secondItem">
                        <label className="col-sm-4 control-label" for="inputEmail3">Name</label>
                        <div className="col-sm-6">
                            <input type="text" name="name" className="form-control " 
                                placeholder="Name" value={"Elysium Admin"} />
                            <span className="text-danger"></span>
                        </div>
                    </div>

                  
                    <div className="form-group secondItem" >
                        <label className="col-sm-4 control-label" for="Logo">Logo</label>
                        <div className="col-sm-6">
                            {/* <input type="file" style={{
                                            fontSize: "1.2rem", margin: "10px 0"}} /> */}

                            <span className="text-danger"></span>

                            <div className="clearfix"></div>
                            <small className="form-text text-muted texted"><strong>*Recommended Dimension: 288 px *
                                    90
                                    px</strong></small>

                      <img src={Logo} width="288" height="90"
                                id="logo-preview" />
                            <span className="remove_img_preview_site_logo"></span>
                        </div>
                    </div>


                 
                    <div className="form-group secondItem">
                        <label className="col-sm-4 control-label" for="Favicon">Favicon</label>
                        <div className="col-sm-6">
                                      {/* <input type="file" style={{
                                            fontSize: "1.2rem", margin: "10px 0"}} /> */}
                            <span className="text-danger"></span>

                            <div className="clearfix"></div>
                            <small className="form-text text-muted texted"><strong>*Recommended Dimension: 40 px *
                                    40
                                    px</strong></small>

                            <div className="setting-img">
                        <img src={favIcon} width="40" height="40"
                                    id="favicon-preview" />
                                <span className="remove_fav_preview"></span>
                            </div>
                        </div>
                    </div>

                   
                    {/* <div className="form-group secondItem">
                        <label for="inputEmail3" className="col-sm-4 control-label">Google Analytics
                            Tracking
                            Code</label>
                        <div className="col-sm-6">
                            <textarea name="head_code" placeholder="Google Analytics Tracking Code"
                                rows="3" className="form-control"></textarea>
                            <span className="text-danger"></span>
                        </div>
                    </div>

                
                    <div className="form-group secondItem" >
                        <label className="col-sm-4 control-label" for="exampleFormControlInput1">Google
                            reCAPTCHA</label>
                        <div className="col-sm-6">
                            <select className="form-control has_captcha" name="has_captcha"
                                id="has_captcha">
                                <option value='login'>Login</option>
                                <option value='registration'>Registration</option>
                                <option value='login_and_registration'>Login And Registration</option>
                                <option value='Disabled' selected>Disabled</option>
                            </select>
                        </div>
                    </div>

                   
                    <div className="form-group secondItem" >
                        <label className="col-sm-4 control-label" for="exampleFormControlInput1">Login
                            Via</label>
                        <div className="col-sm-6">
                            <select className="form-control login_via" name="login_via" id="login_via">
                                <option value='email_only' selected>email only</option>
                                <option value='phone_only'>phone only</option>
                                <option value='email_or_phone'>email or phone</option>
                            </select>
                            <span id="sms-error"></span>
                        </div>
                    </div>

                   
                    <div className="form-group secondItem">
                        <label for="inputEmail3" className="col-sm-4 control-label">Default Currency</label>
                        <div className="col-sm-6">
                            <select className="form-control default_currency" name="default_currency">
                                <option value='1' selected> US Dollar</option>
                                <option value='2'> Pound Sterling</option>
                                <option value='3'> Europe</option>
                            </select>
                        </div>
                    </div>

                   
                    <div className="form-group secondItem">
                        <label for="inputEmail3" className="col-sm-4 control-label">Allowed Crypto
                            Currencies</label>
                        <div className="col-sm-6">
                            <select className="form-control" name="default_crypto_currencies[]"
                                id="default_crypto_currencies" multiple>
                                <option value='4'>DOGETEST</option>
                                <option value='5'>BTCTEST</option>
                            </select>
                        </div>
                    </div>

                  
                    <div className="form-group secondItem">
                        <label for="inputEmail3" className="col-sm-4 control-label">Default Language</label>
                        <div className="col-sm-6">
                            <select className="form-control default_language" name="default_language">
                                <option value='1' selected> English</option>
                                <option value='2'> عربى</option>
                                <option value='3'> Français</option>
                                <option value='4'> Português</option>
                                <option value='5'> Русский</option>
                                <option value='6'> Español</option>
                                <option value='7'> Türkçe</option>
                                <option value='8'> 中文 (繁體)</option>
                            </select>
                        </div>
                    </div> */}
                </div>
               
                {/* <div className="box-footer">
                    <button type="submit" className="btn btns" id="general-settings-submit">
                        <span
                            id="general-settings-submit-text">Submit</span>
                    </button>
                </div> */}
              
            </form>
        </div>
    </div>

</div>

</section>
   
  )
}

export default Settings