import React, { useState } from 'react'
import { useUserHistoryQuery } from './store/ReduxApis'
import Footer from "./Footer";
import { TablePagination } from '@material-ui/core';

function UserHistory() {

    const history = useUserHistoryQuery();

    console.log(history);

    const [userAddress, setUserAddress] = useState("")
    const [searchTerm, setSerchTerm] = useState("")
    const [third, setThird] = useState("");


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    console.log(searchTerm)




    return (
        <div>
            <div class="content-wrapper">

                <section class="content-header">
                    <div class="container-fluid">

                        <div class="row mb-2">
                            <div class="col-sm-6 text">
                                <h1>Users History</h1>
                            </div>
                            <div class="col-sm-6">
                                <ol class="breadcrumb float-sm-right">
                                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                                    <li class="breadcrumb-item active">Users History</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>


                <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header cards">
                                        <h3 class="card-title">Get all the users history details</h3>
                                        <div class="box-tools">
                                          
                                        </div>
                                    </div>


                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <div className='pagination'>
                                                <h6 className='page'>Show 1 to {history?.data?.result.length} entries</h6>
                                                <TablePagination

                                                    className='pagi'
                                                    rowsPerPageOptions={[5, 10, 30]}
                                                    component="div"
                                                    count={history?.data?.result.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onChangePage={handleChangePage}
                                                    onChangeRowsPerPage={handleChangeRowsPerPage}>

                                                </TablePagination>
                                            </div>
                                            <table id="example" class="table table-bordered table-striped table-hover" datatable
                                            >
                                                <thead>
                                                    <tr>

                                                        <th>Wallet</th>
                                                        <th>IP</th>
                                                        <th>Action</th>
                                                        <th>Status</th>
                                                        <th>View</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {history && history?.data?.result?.filter((value) => {
                                                        if (searchTerm === "" && userAddress === "") {
                                                            return value
                                                        } else if (value.user_wallet?.toLowerCase().includes(searchTerm.toLocaleLowerCase()) && value.ip?.toLowerCase().includes(userAddress.toLocaleLowerCase())) {
                                                            return value
                                                        }
                                                    }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                                        <tr className='tr' >

                                                            <td>{e.user_wallet}</td>
                                                            <td>{e.ip}</td>
                                                            <td>{e.action}</td>

                                                            <td>
                                                                {e.status === "success" ? <b>
                                                                    <span class="badge text-white badge-success">Success</span>
                                                                </b> :
                                                                    <b>
                                                                        <span class="badge text-white badge-danger">Fail</span>
                                                                    </b>}
                                                            </td>


                                                            <td>
                                                                <a href="javascript:void(0);"
                                                                    tooltip="view" theme="dark" placement="left"
                                                                    class="btn btn-xs btn-info view">View</a>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }


                                                </tbody>

                                                <tfoot>
                                                    <tr>
                                                        <th ><input type="text" class="form-control" onChange={(e) => setSerchTerm(e.target.value)}
                                                            placeholder="Search Index" name="search-Id" /></th>
                                                        <th ><input type="text" class="form-control" autocomplete="off" onChange={(e) => setUserAddress(e.target.value)}
                                                            placeholder="Search Wallet" name="search-wallet" /></th>
                                                        <th ><input type="text" class="form-control" autocomplete="off"
                                                            placeholder="Search IP" name="search-sourceAmount" /></th>
                                                        <th ><input type="text" class="form-control" autocomplete="off"
                                                            placeholder="Search Action" name="Search Target Amount" /></th>
                                                        <th ><input type="text" class="form-control" autocomplete="off"
                                                            placeholder="Search Date&Time" name="search-Status" /></th>

                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>

                                </div>



                            </div>

                        </div>


                    </div>

                </section>
                <Footer></Footer>

            </div>

        </div>
    )
}

export default UserHistory