import React, { useEffect, useState } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { useAdminSettingsQuery, useGlobalSettngsQuery, useNetworkDetailsQuery, useUpdateAdminAccountMutation } from './store/ReduxApis';
import toast, { Toaster } from 'react-hot-toast';




function EmailSettings() {

  const details = useAdminSettingsQuery();
  const [adminAddress, setAdminAddress] = useState("")
  const [privateKeyAddress, setPrivateKeyAddress] = useState("")

  const [createPost, responseInfo] = useUpdateAdminAccountMutation();

  console.log(responseInfo);

  console.log(details, "global")

  const newPost = {
    
    
      "adminAddress": adminAddress,
      "adminPrivateKey": privateKeyAddress
  
  
  }
  
  const tokenValue = (e) => {
    e.preventDefault()
    createPost(newPost)

  }
    
        
  const privateKey = details?.data?.settings?.adminAccountDetails?.adminPrivateKey
  const adminAccount = details?.data?.settings?.adminAccountDetails?.adminAddress
  
  useEffect(() => {

    if (responseInfo?.data?.status === "success") {
      toast(responseInfo?.data?.message)
     
  } 
      
  }, [responseInfo])

  useEffect(() => {
    setAdminAddress(adminAccount);
    setPrivateKeyAddress(privateKey)
  }, [])

 
  return (

    <section className="content pages">
       <Toaster />

      <div className='mainPage'>
         <h3>Admin Details Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
        
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
          </div>


          </div>
          </div>
         
          <div className="col-md-8" style={{width: "60%"}}>
        <div className="box box-info">
            <div className="box-header with-border">
                <h5 className="title">Admin Details Settings</h5>
            </div>

            <div className='setting'>
                          <div className="path">
                              <label>Admin Address</label>
                              <input type="text" value={adminAddress} onChange={(e) => setAdminAddress(e.target.value)} style={{ marginLeft: "15px" }}></input>
                          </div>
                          <div className="path">
                              <label>Admin Private Key</label>
                              <input type="text" value={privateKeyAddress} onChange={(e) => setPrivateKeyAddress(e.target.value)} ></input>
              </div >
             
                       
              <button className='btn btns' onClick={(e) => tokenValue(e)}>Submit</button>
                
                          
                      </div>
        </div>
    </div>

</div>

</section>
   
  )
}

export default EmailSettings