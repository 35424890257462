import React from 'react'


import Key from '../assets/273.jpg'
import { Link } from 'react-router-dom'

function HederBar() {

   
  
    return (

        <div className='headerbar'>
            {/* <div className='headerItems'>
                <img src={Key} className='headerImg'></img>
                <p>Admin Panel</p>
                
            </div> */}
           
        </div>


    )
}

export default HederBar