import React, {useState} from 'react'
import { useBuyTransactionsQuery } from './store/ReduxApis';
import Footer from "./Footer";
import { TablePagination } from '@material-ui/core';

function BuyTransactions() {

    const trans = useBuyTransactionsQuery();
    const [userAddress, setUserAddress] = useState("")
  const [searchTerm, setSerchTerm] = useState("")

    console.log(trans);




const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
 

  return (
      <div>
          <div class="content-wrapper">
    
    <section class="content-header">
        <div class="container-fluid">
           
            <div class="row mb-2">
                <div class="col-sm-6 text">
                    <h1>Buy Transaction List</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Buy Transaction List</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>

   

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header cards">
                            <h3 class="card-title">Get all the Buy Transaction details</h3>
                            <div class="box-tools">
                                
                            </div>
                        </div>

                        
                                  <div class="card-body">
                                  <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {trans?.data?.data?.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 30]}
 component="div"
 count={trans?.data?.data?.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                            <div class="table-responsive">
                            <table id="example" class="table table-bordered table-striped table-hover" datatable
                               >
                                <thead>
                                    <tr>
                                     
                                        <th>Wallet Address</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Date</th>

                                       
                                    </tr>
                                </thead>

                                <tbody>

                                { trans && trans?.data?.data?.filter((value) => {
  if (searchTerm === "" && userAddress === "") {
    return value
  } else if (value.from.toLowerCase().includes(searchTerm.toLowerCase()) && value.sourceAmount.toLowerCase().includes(userAddress.toLowerCase())) {
    return value
  } 
}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                                        <tr className='tr'>
                                                        
                                                              <td>{e.from}</td>
                                                              <td>{e.sourceAmount}</td>
                                                              <td>
                                                           <b>
                                                               <span class="badge text-white badge-success">Completed</span>
                                                           </b>
                                                         
                                                              </td>
                                                              <td>{new Date(Number(e.timestamp)).toLocaleDateString()}</td>
                                                            
                                                   
                                                       
               
               
                                                     
                                                   </tr>
                                                      ))
                                                  }
                                </tbody>
                                
                                <tfoot>
                                    <tr>
                                        <th><input type="text" class="form-control" onChange={(e) => setSerchTerm(e.target.value)} autocomplete="off"
                                                placeholder="Wallet Address" name="search-Id" /></th>
                                        <th><input type="text" class="form-control" onChange={(e) => setUserAddress(e.target.value)} autocomplete="off"
                                                placeholder="Amount" name="search-Id" /></th>
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Status" name="search-mail" /></th>
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Date" name="search-sourceAmount" /></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        </div>
                        
                    </div>
                  

                  
                </div>
            
            </div>
            

        </div>
      
    </section>
    <Footer></Footer>
</div>
    </div>
  )
}

export default BuyTransactions