import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAdminSettingsQuery } from "./store/ReduxApis";
import toast, { Toaster } from 'react-hot-toast';
// import {  useEmailTemplateQuery } from "./store/ReduxApis";

function EmailTemplate() {
  const [logo, setLogo] = useState("");
  const [alt, setalt] = useState("");
  const [footer, setFooter] = useState("");
  const [mailingAddress, setMailingAddress] = useState("");


  const token = localStorage.getItem('nugenToken')

  const data = useAdminSettingsQuery();

  console.log(data)

  const logos = data?.data?.settings?.emailTemplate?.logos;
  const alts = data?.data?.settings?.emailTemplate?.alt;
  const footers = data?.data?.settings?.emailTemplate?.footer;
  const mailingAddresss = data?.data?.settings?.emailTemplate?.mailingAddress;





  console.log(logo);

  const email = (e) => {
    e.preventDefault();
    let formData = new FormData();
   
    formData.append("alt", "Neugen");
    formData.append(
      "footer",
      "Copyright &copy 2022.  Neugen.io. All Rights Reserved."
    );
    formData.append("mailingAddress", "info@neugen.com");

    const options = { content: formData };

    console.log(options);
    // createPost(options);
  };

  const handleLogin = async (e) => {

    e.preventDefault();
    
    let formData = new FormData();
    formData.append("logo", logo)
    formData.append("alt", alt);
    formData.append(
      "footer",
      footer
    );
    formData.append("mailingAddress", mailingAddress);

    const options = { content: formData };

    console.log(options);

  
    let url = await fetch(`https://apiadmin.elysiumlila.com/admin/updateEmailTemplates`, {
      method: 'post',
      headers: {
      
        "Accept": 'application/json',
        "Authorization": token
      },
    
      body: formData
    })

    if (url.status === 200) {
      toast("Succefully EmailTemplate Added")
     } 

    console.log(url)
   
   

  }

  useEffect(() => {

    setLogo(logos)
    setalt(alts);
    setFooter(footers)
    setMailingAddress(mailingAddresss)

  }, [])






  const newPost = {
    "logo": logo,
    "alt": "Neugen",
    "footer": "Copyright &copy 2022.  Neugen.io. All Rights Reserved.",
    "mailingAddress": "info@neugen.com"



  }

  return (
    <section className="content pages">
      <Toaster />
      <div className="mainPage">
        <h3>Email Template Settings</h3>
      </div>
      <div className="row">
        <div className="firstBox">
          <div className="box box-info">
            <div className="box-header with-border">
              <h5 className="title">Manage Settings</h5>
            </div>

            <div className="icons">
              <i class="fas fa-cog"></i>
              <li>
                <Link className="link" to="/general">
                  General
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fas fa-lock"></i>
              <li>
                <Link className="link" to="/emailSettings">
                  Email Settings
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fas fa-cog"></i>
              <li>
                <Link className="link" to="/email">
                  Email Template Settings
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fas fa-share-alt"></i>
              <li>
                <Link className="link" to="/password">
                  Password Settings
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fas fa-cog"></i>
              <li>
                <Link className="link" to="/account">
                  Token Contribution Settings
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fab fa-google"></i>
              <li>
                <Link className="link" to="/contractAddress">
                  Contract Address Settings
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fab fa-google"></i>
              <li>
                <Link className="link" to="/details">
                  Admin Details
                </Link>
              </li>
            </div>
            <div className="icons">
              <i class="fas fa-cog"></i>
              <li>
                <Link className="link" to="/status">
                Social Media Link
                </Link>
              </li>
            </div>
          </div>
        </div>

        <div style={{ width: "60%" }}>
          <div className="box box-info">
            <h5 className="title">Email Template Settings</h5>
            <div className="setting">
              <div className="path">
                <label>Logo Path</label>
                <input
                  type="file"
                  onChange={(e) => setLogo(e.target.files[0])}
                  style={{ marginLeft: "-20px", border: "1px solid #111" }}
                ></input>
              </div>
              <div className="path">
                <label>Alt</label>
                <input
                  type="text"
                  value={alt}
                  onChange={(e) => setalt(e.target.value)}
                  style={{ marginLeft: "40px" }}
                ></input>
              </div>
              <div className="path">
                <label>Footer</label>
                <input
                  type="text"
                  value={footer}
                  onChange={(e) => setFooter(e.target.value)}
                  style={{ marginLeft: "10px" }}
                ></input>
              </div>
              <div className="path">
                <label>Mailing Address</label>
                <input
                  type="text"
                  value={mailingAddress}
                  style={{ marginLeft: "-55px" }}
                  onChange={(e) => setMailingAddress(e.target.value)}
                ></input>
              </div>
            </div>
            <button className="btn btns" onClick={(e) => handleLogin(e)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmailTemplate;
