import "./App.css";
import SideMenu, { menuItems } from "./components/SideMenu";


import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useState } from "react";
import HeaderBar from './components/HederBar';
import RegisterSettings from "./components/subsettings/RegisterSettings";
import EmailSettings from "./components/EmailSettings";
import PasswordSettings from "./components/PasswordSettings";
import AccountSettings from './components/AccountSettings';
import GoogleCapcha from "./components/GoogleCapcha";
import Status from './components/Status';
import Settings from "./components/Settings";

import Login from "./components/Login";
import UserWallet from "./components/UserWallet";
import UserHistory from "./components/UserHistory";
import LockTokenList from "./components/LockTokeList";
import RevertLockHistory from "./components/RevertLockHistory";
import BuyTransactions from "./components/BuyTransactions";
import LockedTransactions from "./components/LockedTransactions";
import AddUser from "./components/AddUser";
import EmailTemplate from "./components/EmailTemplate";
import MarketWallet from "./components/SeedTransactions"
import ProductTransactions from "./components/EcosystemTransactions";
import MarketTransactions from "./components/MarketWalletTransaction";

import AdminDetails from "./components/AdminDetails";
import { useAdminSettingsQuery, useGlobalSettngsQuery } from "./components/store/ReduxApis";
import OperationTransactions from "./components/AdvisoryTransactions";
import PartnerashipTransactions from "./components/PartshipTransaction";



import TeamTransactions from "./components/TeamTransactions";
import AddWalletTransactions from "./components/AddSeedTransactions"
import AddEcosystemTransactions from "./components/AddEcosystemTransactions";
import AddMarket from "./components/AddMarketToken";
import AddTeam from "./components/AddTeamTransactions";
import AddOperation from "./components/AddAdvisoryTransactions";

import BurnList from "./components/BurnList";
import AddBurn from "./components/AddBurn";
import Network from "./components/NetworkDetails";

import MarketRevoke from "./components/revoke/MarketRevoke";
import TeamRevoke from "./components/revoke/TeamRevoke";
import EcosystemRevoke from "./components/revoke/EcosystemRevoke";
import SeedRevoke from "./components/revoke/SeedRevoke";
import AdvisoryRevoke from "./components/revoke/AdvisoryRevoke";
import ReserveRevoke from "./components/revoke/ReserveRevoke";
import PartnershipRevoke from "./components/revoke/PartnershipRevoke";


import MarketAddRevoke from "./components/AddRevoke/AddMarketRevoke";
import TeamAddRevoke from "./components/AddRevoke/AddTeamRevoke";
import EcosystemAddRevoke from "./components/AddRevoke/AddEcosystem";
import OperationAddRevoke from "./components/AddRevoke/AddPlatform";
import AdvisoryAddRevoke from "./components/AddRevoke/AdddAdvisory";
import ReserveAddRevoke from "./components/AddRevoke/AddReserveRevoke";
import PartnesrhipAddRevoke from "./components/AddRevoke/AddPartnershipRevoke";

import Reserve from "./components/AddReserveToken";
import PartnershipToken from "./components/AddPartnershipToken"
import ReserveTransaction from "./components/ReserveTransaction";
import Referral from "./components/ReferralTransactions";




function App() {

  const [inactive, setInactive] = useState(false);
  const [login, setLogin] = useState(false);
  const token = localStorage.getItem("nugenToken");



  const data = useAdminSettingsQuery();

  console.log(data)



  

  return (
    <div className="App">


   
     
      <Router>

        {token === null ? <Login></Login> : 
          <div>
         <SideMenu
         onCollapse={(inactive) => {
           console.log(inactive);
           setInactive(inactive);
         }}
       />
     
        
       <div className={`container ${inactive ? "inactive" : ""}`}>
         <HeaderBar></HeaderBar>

          
       
         
         
         <Route path="/emailSettings" exact><EmailSettings></EmailSettings></Route>
         <Route path="/password" exact><PasswordSettings></PasswordSettings></Route>
         <Route path="/account" exact><AccountSettings></AccountSettings></Route>
         <Route path="/contractAddress" exact><GoogleCapcha></GoogleCapcha></Route>
         <Route path="/status" exact><Status></Status></Route>
              <Route path="/general" exact><Settings></Settings></Route>
              
              <Route path="/content/userbalance" exact><UserWallet></UserWallet></Route>
              <Route path="/content/userHistory" exact><UserHistory></UserHistory></Route>

              <Route path="/content/lockToken" exact><LockTokenList></LockTokenList></Route>
              <Route path="/content/reverttoken" exact><RevertLockHistory></RevertLockHistory></Route>

              <Route path="/content/buytransactions" exact><BuyTransactions></BuyTransactions></Route>
              <Route path="/content/lockedtransactions" exact><LockedTransactions></LockedTransactions></Route>

              <Route path="/content/adduser" exact><AddUser></AddUser></Route>
              <Route path="/email" exact><EmailTemplate></EmailTemplate></Route>

              <Route path="/details" exact><AdminDetails></AdminDetails></Route>

              <Route path="/content/seedtransactions" exact><MarketWallet></MarketWallet></Route>

              <Route path="/content/ecosystemTransactions" exact><ProductTransactions></ProductTransactions></Route>
             
              <Route path="/content/advisoryTransactions" exact><OperationTransactions></OperationTransactions></Route>

              <Route path="/content/teamTransactions" exact><TeamTransactions></TeamTransactions></Route>


             
              <Route path="/content/reserveTransactions" exact><ReserveTransaction></ReserveTransaction></Route>

              <Route path="/content/markettransactions" exact><MarketTransactions></MarketTransactions></Route>
              <Route path="/content/partnershiptransactions" exact><PartnerashipTransactions></PartnerashipTransactions></Route>
             
             
              <Route path="/content/referralTransactions" exact><Referral></Referral></Route>
             
              <Route path="/content/platformTransactions" exact><TeamTransactions></TeamTransactions></Route>
              <Route path="/seed" exact><AddWalletTransactions></AddWalletTransactions></Route>
              <Route path="/team" exact><AddTeam></AddTeam></Route>
               <Route path="/ecosystem" exact><AddEcosystemTransactions></AddEcosystemTransactions></Route>
              <Route path="/advisory" exact><AddOperation></AddOperation></Route>
              <Route path="/partnership" exact><PartnershipToken></PartnershipToken></Route>


              <Route path="/market" exact><AddMarket></AddMarket></Route>
            

              <Route path="/reserve" exact><Reserve></Reserve></Route>

              
              <Route path="/burn" exact><BurnList></BurnList></Route>
              <Route path="/addBurn" exact><AddBurn></AddBurn></Route>

              <Route path="/network" exact><Network></Network></Route>

              <Route path="/marketRevoke" exact><MarketRevoke></MarketRevoke></Route>
              <Route path="/teamRevoke" exact><TeamRevoke></TeamRevoke></Route>
              <Route path="/advisoryRevoke" exact><AdvisoryRevoke></AdvisoryRevoke></Route>
              <Route path="/seedRevoke" exact><SeedRevoke></SeedRevoke></Route>
              <Route path="/ecosystemRevoke" exact><EcosystemRevoke></EcosystemRevoke></Route>
              <Route path="/reserveRevoke" exact><ReserveRevoke></ReserveRevoke></Route>
              <Route path="/partnershipRevoke" exact><PartnershipRevoke></PartnershipRevoke></Route>



              <Route path="/marketAddRevoke" exact><MarketAddRevoke></MarketAddRevoke></Route>
              <Route path="/advisoryAddRevoke" exact><AdvisoryAddRevoke></AdvisoryAddRevoke></Route>
              <Route path="/teamAddRevoke" exact><TeamAddRevoke></TeamAddRevoke></Route>
              <Route path="/seedAddRevoke" exact><OperationAddRevoke></OperationAddRevoke></Route>
              <Route path="/ecosystemAddRevoke" exact><EcosystemAddRevoke></EcosystemAddRevoke></Route>
              <Route path="/reserveAddRevoke" exact><ReserveAddRevoke></ReserveAddRevoke></Route>
              <Route path="/partnershipAddRevoke" exact><PartnesrhipAddRevoke></PartnesrhipAddRevoke></Route>

           
             
              
            
            
            
             
             
              
             
             
             
             
             
             
             
             
             
              
             
              
        
       
       
        
       
       
         
        
       
         
         
         
         
         
         

         {/* improvememt, not recorded in video, its just looping through menuItems
         instead of hard coding all the routes */}
         {menuItems.map((menu, index) => (
           <>
             
             <Route key={menu.name} exact={menu.exact} path={menu.to}>
               <h1>{menu.items}</h1>
             </Route>
             {menu.subMenus && menu.subMenus.length > 0
               ? menu.subMenus.map((subMenu, i) => (
                   <Route key={subMenu.name} path={subMenu.to}>
                     <h1>{subMenu.compo}</h1>
                   </Route>
                 ))
               : null}
           </>
         ))}
          
       

        

         {/* <Switch>
           <Route exact path={"/"}>
             <Dashboard />
           </Route>
          
           <Route path={"/content/courses"}>
             <Courses />
           </Route>
           <Route path={"/content/videos"}>
             <Videos />
           </Route>
           <Route path={"/design"}>
             <Design />
           </Route>
           <Route exact path={"/content-2"}>
             <Content2 />
           </Route>
           <Route path={"/content-2/courses"}>
             <Courses2 />
           </Route>
           <Route path={"/content-2/videos"}>
             <Videos2 />
           </Route>
           <Route path={"/design-2"}>
             <Design2 />
           </Route>
         </Switch> */}
            </div>
            </div>
       }


     
       
      </Router>
    </div>
  );
}

export default App;
