import React from 'react';
import { useGlobalSettngsQuery } from './store/ReduxApis';



function Footer() {

    const foot = useGlobalSettngsQuery();

    console.log(foot)
    const footerName = foot?.data?.data[0]?.emailTemplate?.footer;

    return (
        <div>
            <footer class="main-footer footerPage">
                <div class="float-right d-none d-sm-block">
     
                </div>
                <strong>{footerName}</strong>
            </footer>
        </div>
    )

  
}
export default Footer
