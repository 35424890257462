import React, {useEffect, useState} from 'react'
import { useLockTransactionsQuery, useMarketRevokeQuery, useMarketWalletDistrubutionQuery, useOperationRevokeQuery, useTeamWalletDistrubutionQuery } from '../store/ReduxApis';
import Footer from "../Footer";
import ReactPaginate from 'react-paginate';
import { TablePagination } from '@material-ui/core';

function TeamTransactions() {

    const lock = useTeamWalletDistrubutionQuery();
    const revoke = useOperationRevokeQuery();

    console.log(revoke)

    const [userAddress, setUserAddress] = useState("")
    const [searchTerm, setSerchTerm] = useState("")
    
    console.log(lock)

//   const [users, setUsers] = useState(lock?.data?.data?.slice(0, 50));
//   const [pageNumber, setPageNumber] = useState(0);

//   const usersPerPage = 10;
//   const pagesVisited = pageNumber * usersPerPage;

  

//   const pageCount = Math.ceil(users?.length / usersPerPage);

//   const changePage = async ( data ) => {
   
//   };
    
const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
    
   


  return (
    <div>
    <div class="content-wrapper">

<section class="content-header">
  <div class="container-fluid">
     
      <div class="row mb-2">
          <div class="col-sm-6 text">
              <h1>Seed Distribution Revoke</h1>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Seed Distribution Revoke</li>
              </ol>
          </div>
      </div>
  </div>
</section>



<section class="content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-header cards">
                      <h3 class="card-title">Get all the Seed Distribution Revoke details</h3>
                      <div class="box-tools">
                          <button type="button" class="btn btn-primary pull-right">
                              <i class="fa fa-refresh"></i> Rerender
                          </button>
                      </div>
                  </div>

                  
                  <div class="card-body">
                                      <div class="table-responsive">
                                      <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {lock?.data?.transactions?.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 25]}
 component="div"
 count={lock?.data?.transactions?.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                      <table id="example" class="table table-bordered table-striped table-hover" datatable
                         >
                          <thead>
                              <tr>
                                 
                                  <th>From</th>
                                  <th>Source</th>
                                                      <th>Status</th>
                                                      <th>Target Amount</th>
                                  <th>Timestamp</th>
                                  <th>To</th>
                                  <th>TxHash</th>
                              </tr>
                          </thead>

                          <tbody>

                                                   {revoke && revoke?.data?.transactions?.filter((value) => {
    if (searchTerm === "" && userAddress === "") {
      return value
    } else if (value.txHash?.toLowerCase().includes(searchTerm.toLowerCase()) && value.walletAddress?.toLowerCase().includes(userAddress.toLowerCase())) {
      return value
    } 
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => {
        
                                                          return (
                                                              <tr className='tr'>
                                                  
                                                                  <td>{e.from}</td>
                                                                  <td>{e.source}</td>
                                                                 
                                                                  <td>{e.status}</td>
                                                                  <td>{e.targetAmount}</td>
                                                                  <td>
                                                                     {e.timestamp}
                                                                    
                                                                  </td>
                                                                  <td> {e.to}
                                                                     </td>
                                                                     <td>{e.txHash}</td>
                                                              </tr>
                                                          );
                                                      })}
                                                  
                                           
                                                      

                                                  {/* <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
                                                  
                                                 
                                                      
                                                      
                          
                                              </tbody>
                                              
                                             
                          <tfoot>
                              <tr>
                                  <th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setSerchTerm(e.target.value)}
                                          placeholder="Search From" name="search-Id" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"                                           placeholder="Search To" name="search-Id" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"
                                          placeholder="Search Source" name="search-mail" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"
                                          placeholder="Search Source Amount" name="search-sourceAmount" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setUserAddress(e.target.value)}

                                          placeholder="Search Target Amount" name="Search Target Amount" /></th>
                                             <th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setUserAddress(e.target.value)}

placeholder="Search Target Amount" name="Search Target Amount" /></th>

<th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setUserAddress(e.target.value)}

placeholder="Search Target Amount" name="Search Target Amount" /></th>
                   
                              </tr>
                          </tfoot>
                      </table>
                  </div>
                  </div>
                  
              </div>
            

            
          </div>
      
      </div>
      

  </div>

              </section>
              <Footer></Footer>

</div>
</div>
  )
}

export default TeamTransactions;