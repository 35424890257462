import { configureStore } from '@reduxjs/toolkit';

import {getUserDetails}   from '../components/store/ReduxApis';


export default configureStore({
  reducer: {
    [getUserDetails.reducerPath]: getUserDetails.reducer,
    
  },
});