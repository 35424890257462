import React, {useState , useEffect} from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { useAdminSettingsQuery, useContractUpdateMutation } from './store/ReduxApis';
import toast, { Toaster } from 'react-hot-toast';






function GoogleCapcha() {
  const contract = useAdminSettingsQuery();

  const [contractAddress, setContractAddress] = useState("")
  const [presaleAddress, setPresaleAddress] = useState("")
  const [timelockAddress, setTimelockAddress] = useState("")
  const [teamAddress, setTeamAddress] = useState("")
  const [advisoryAddress, setAdvisoryAddress] = useState("");
  const [mintAddress, setMintAddress] = useState("");
  const [reserveAddress, setReserveAddress] = useState("");
  const [ecoAddress, setEcoAddress] = useState("");
  const [publicAddress, setPublicAddress] = useState("");


  const [createPost, responseInfo] = useContractUpdateMutation();

  const newPost = {
    "elsyium": contractAddress,
    "ico": presaleAddress,
    "airdrop": timelockAddress,
    "advisory": advisoryAddress,
    "partnership": teamAddress,
    "rewardpool": ecoAddress,
    "operational": mintAddress,
    "reserve": reserveAddress,
  }



  const tokenValue = (e) => {
    e.preventDefault(e)
    createPost(newPost)
  }

  console.log(responseInfo)



  const presale = contract.data?.settings?.smartContractAddress.ico
  const address = contract.data?.settings?.smartContractAddress.elsyium
  const timelock = contract.data?.settings?.smartContractAddress.airdrop
  const advisory = contract.data?.settings?.smartContractAddress.advisory
  const team = contract.data?.settings?.smartContractAddress.partnership
  const reserve = contract.data?.settings?.smartContractAddress.reserve;
  const eco = contract.data?.settings?.smartContractAddress.rewardpool;
  const operational = contract.data?.settings?.smartContractAddress.operational;


  useEffect(() => {

   

    setPresaleAddress(presale);
    setContractAddress(address);
    setTimelockAddress(timelock)
    setAdvisoryAddress(advisory)
    setTeamAddress(team)
    setReserveAddress(reserve)
    setEcoAddress(eco)
    setMintAddress(operational)
   


  }, [])

  useEffect(() => {
    if (responseInfo?.data?.status === "success") {
      toast(responseInfo?.data?.message)
     
  } 
  }, [responseInfo])
  

  console.log(contract)


  return (

    <section className="content pages">
 <Toaster />
<div className='mainPage'>
         <h3>Contract Address Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
            
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
            </div>
          </div>
          </div>
         
          <div style={{ width: "60%" }}>
                  <div className='box box-info'>
                      <h5 className="title">Contract Address Settings</h5>
                      <div className='setting'>
                          <div className="path">
                              <label>Elysium Coin</label>
                              <input type="text" value={contractAddress} style={{ marginLeft: "30px" }} onChange={(e) => setContractAddress(e.target.value)}></input>
                          </div>
                          <div className="path">
                              <label>Private Address</label>
                              <input type="text" value={presaleAddress} style={{ marginLeft: "10px" }} onChange={(e) => setPresaleAddress(e.target.value)}></input>
                          </div >
                            <div className="path">
                              <label>Airdrop Address</label>
                              <input type="text" value={timelockAddress} style={{ marginLeft: "10px" }} onChange={(e) => setTimelockAddress(e.target.value)}></input>
              </div>
              <div className="path">
                              <label>Advisory Address</label>
                              <input type="text" value={advisoryAddress} style={{ marginLeft: "10px" }} onChange={(e) => setAdvisoryAddress(e.target.value)}></input>
              </div>
                        
                          <div className="path">
                              <label>Partner Address</label>
                              <input type="text" value={teamAddress} style={{ marginLeft: "5px" }} onChange={(e) => setTeamAddress(e.target.value)}></input>
              </div>
             
            
              <div className="path">
                              <label>Reward Address</label>
                              <input type="text" value={ecoAddress} style={{ marginLeft: "10px" }} onChange={(e) => setEcoAddress(e.target.value)}></input>
              </div>
              <div className="path">
                              <label>Operation Address</label>
                              <input type="text" value={mintAddress} style={{ marginLeft: "-10px" }} onChange={(e) => setMintAddress(e.target.value)}></input>
              </div>
              <div className="path">
                              <label>Reserve Address</label>
                              <input type="text" value={reserveAddress} style={{ marginLeft: "10px" }} onChange={(e) => setReserveAddress(e.target.value)}></input>
              </div>

                          
                      </div>
                      <button className='btn btns' onClick={(e) => tokenValue(e)}>Submit</button>
                  </div>
            
      
    </div>

</div>

</section>
   
  )
}

export default GoogleCapcha