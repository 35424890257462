import React, {useEffect, useState} from 'react'
import { useLockTransactionsQuery, useMarketWalletDistrubutionQuery, usePartnershipWalletDistrubutionQuery } from './store/ReduxApis';
import Footer from "./Footer";
import ReactPaginate from 'react-paginate';
import { TablePagination } from '@material-ui/core';

function MarketWallet() {

    const lock = usePartnershipWalletDistrubutionQuery();

    const [userAddress, setUserAddress] = useState("")
    const [searchTerm, setSerchTerm] = useState("")
    
    console.log(lock)

    
const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
    
   


  return (
    <div>
    <div class="content-wrapper">

<section class="content-header">
  <div class="container-fluid">
     
      <div class="row mb-2">
          <div class="col-sm-6 text">
              <h1>Partnership Distribution Wallet</h1>
          </div>
          <div class="col-sm-6">
              <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Partnership Distribution Wallet</li>
              </ol>
          </div>
      </div>
  </div>
</section>



<section class="content">
  <div class="container-fluid">
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div class="card-header cards">
                      <h3 class="card-title">Get all the Partnership Distribution Wallet details</h3>
                      <div class="box-tools">
                      
                      </div>
                  </div>

                  
                  <div class="card-body">
                                      <div class="table-responsive">
                                      <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {lock?.data?.transactions?.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 25]}
 component="div"
 count={lock?.data?.transactions?.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                      <table id="example" class="table table-bordered table-striped table-hover" datatable
                         >
                          <thead>
                              <tr>
                                 
                                  <th>Tx Hash</th>
                                  <th>Amount</th>
                                                      <th>Create Date</th>
                                                      <th>Start Time</th>
                                  <th>Wallet Address</th>
                              </tr>
                          </thead>

                          <tbody>

                                                   {lock && lock?.data?.transactions?.filter((value) => {
    if (searchTerm === "" && userAddress === "") {
      return value
    } else if (value.txHash?.toLowerCase().includes(searchTerm.toLowerCase()) && value.walletAddress?.toLowerCase().includes(userAddress.toLowerCase())) {
      return value
    } 
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((e) => {
        
                                                          return (
                                                              <tr className='tr'>
                                                  
                                                                  <td>{e.txHash}</td>
                                                                  <td>{e.amount}</td>
                                                                 
                                                                  <td>{new Date(Number(e.createdTime)).toLocaleDateString()}</td>
                                                                  <td>{new Date(Number(e.startTime * 1000)).toLocaleTimeString()}</td>
                                                               
                                                                  <td>
                                                                     {e.walletAddress}
                                                                  </td>
                                                              </tr>
                                                          );
                                                      })}
                                                  
                                           
                                                      

                                                  {/* <ReactPaginate
        previousLabel={"Previous"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={changePage}
        containerClassName={"paginationBttns"}
        previousLinkClassName={"previousBttn"}
        nextLinkClassName={"nextBttn"}
        disabledClassName={"paginationDisabled"}
        activeClassName={"paginationActive"}
      /> */}
                                                  
                                                 
                                                      
                                                      
                          
                                              </tbody>
                                              
                                             
                          <tfoot>
                            
                          <tr>
                                  <th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setSerchTerm(e.target.value)}
                                          placeholder="Tx Hash" name="search-Id" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"                                           placeholder="Amount" name="search-Id" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"
                                          placeholder="Date" name="search-mail" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off"
                                          placeholder="Start Time" name="search-sourceAmount" /></th>
                                  <th><input type="text" class="form-control" autocomplete="off" onChange={(e) => setUserAddress(e.target.value)}

                                          placeholder="Wallet Address" name="Search Target Amount" /></th>
                            
                              </tr>
                          </tfoot>
                      </table>
                  </div>
                  </div>
                  
              </div>
            

            
          </div>
      
      </div>
      

  </div>

              </section>
              <Footer></Footer>

</div>
</div>
  )
}

export default MarketWallet;