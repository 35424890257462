import React from 'react'
import { useDashboardQuery, useReferralGetDataQuery } from "./store/ReduxApis";
import Footer from "./Footer";
import { Link } from "react-router-dom"



function Dashboard() {

  const dashboard = useDashboardQuery();

  const referral = useReferralGetDataQuery();

  

 

  return (
      <div>
          <div class="content-wrapper">
  
 
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6 text">
          <h1 class="m-0 text-dark">Dashboard</h1>
        </div>
        <div class="col-sm-6 text">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active">Dashboard</li>
                  
          </ol>
        </div>
      </div>
    </div>
  </div>
  


  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 col-sm-6">
          <div class="card">
            
          </div>
        </div>
            </div> 
           
            <div class="row">

            <div class="col-lg-3 col-6">
         
         <div class="small-box bg-success">
           <div class="inner">
                   <h3>{dashboard?.data?.total_users}</h3>
             <p>Total Users</p>
           
           </div>
           <div class="icon">
             <i class="ion ion-person-add"></i>
           </div>
           <Link to="/content/userbalance" class="small-box-footer" >More info <i class="fas fa-arrow-circle-right"></i></Link>
         </div>
       </div>


       <div class="col-lg-3 col-6">
         
         <div class="small-box bg-red">
           <div class="inner">
                   <h3>{dashboard?.data?.total_admin}</h3>

             <p>Total Admins</p>
           </div>
           <div class="icon">
             <i class="ion ion-person-add"></i>
           </div>
           <Link to="/design-2" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
         </div>
       </div>


      

       

{/* 
       <div class="col-lg-3 col-6">
         <div class="small-box bg-maroon">
           <div class="inner">
                   <h3>{dashboard?.data?.unlock_trans_count}</h3>

             <p> Unlocked Transactions</p>
           </div>
           <div class="icon">
             <i class="ion ion-stats-bars"></i>
           </div>
           <a href="javascript:void(0);"  class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
         </div>
       </div>


       <div class="col-lg-3 col-6">
         <div class="small-box bg-blue">
           <div class="inner">
                   <h3>{dashboard?.data?.lock_trans_count}</h3>

             <p> User Locked Transactions</p>
           </div>
           <div class="icon">
             <i class="ion ion-stats-bars"></i>
           </div>
           <a href="/content/lockedtransactions"  class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a>
         </div>
       </div> */}

       
       <div class="col-lg-3 col-6">
         <div class="small-box bg-yellow">
           <div class="inner">
                  <h3>{dashboard?.data?.total_buy_trans_count}</h3>

             <p> Total Buy Transactions</p>
           </div>
           <div class="icon">
             <i class="ion ion-stats-bars"></i>
           </div>
           <Link to="/content/buytransactions" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
         </div>
       </div>

       <div class="col-lg-3 col-6">
         <div class="small-box bg-blue">
           <div class="inner">
                  <h3>{referral?.data?.message?.length}</h3>

             <p> Total Referral Transactions</p>
           </div>
           <div class="icon">
             <i class="ion ion-stats-bars"></i>
           </div>
           <Link to="/content/referralTransactions" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></Link>
         </div>
       </div>

       


  

      
       <div class="clearfix hidden-md-up"></div>

      
       <div class="clearfix hidden-md-up"></div>




</div>
  

       {/* <div class="row">
        <div class="col-12 col-sm-8">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="card card-orange">
                    <div class="card-body">
                      <h1 class="users_res">4</h1>
                      <div class="users_text">Total Users</div>
                    </div>
                    <div class="card-footer info_text">
                      <div class="more_info">
                        <a class="more_text" href="javascript:void(0)" >More Info</a>&nbsp;<i class="fa fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-red">
                    <div class="card-body">
                      <h1 class="users_res">4</h1>
                      <div class="users_text">Total Merchants</div>
                    </div>
                    <div class="card-footer info_text">
                      <div class="more_info">
                        <a class="more_text" href="javascript:void(0)" >More Info</a>&nbsp;<i class="fa fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="card card-blue">
                    <div class="card-body">
                      <h1 class="users_res">3</h1>
                      <div class="users_text">Total Tickets</div>
                    </div>
                    <div class="card-footer info_text">
                      <div class="more_info">
                        <a class="more_text" href="javascript:void(0)" >More Info</a>&nbsp;<i class="fa fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-green">
                    <div class="card-body">
                      <h1 class="users_res">2</h1>
                      <div class="users_text">Total Dispute</div>
                    </div>
                    <div class="card-footer info_text">
                      <div class="more_info">
                        <a class="more_text" href="javascript:void(0)">More Info</a>&nbsp;<i class="fa fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  */}

      {/* <div class="row">
        <div class="col-12 col-sm-8">
          <div class="card">
            <div class="card-body">
              <div class="l_ticket">Latest Ticket</div>
              <table>
                <tr>
                  <th>Subject</th>
                  <th>User</th>
                  <th>Priority</th>
                  <th>Created Date</th>
                </tr>
                <tr>
                  <td>New Tickets</td>
                  <td>Watson</td>
                  <td>Normal</td>
                  <td>10-01-21</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>  */}

      {/* <div class="row">
        <div class="col-12 col-sm-8">
          <div class="card">
            <div class="card-body">
              <div class="l_ticket">Latest Dispute</div>
              <table>
                <tr>
                  <th>Dispute</th>
                  <th>Claimant</th>
                  <th>Created Date</th>
                </tr>
                <tr>
                  <td>New Tickets</td>
                  <td>Watson</td>
                  <td>Normal</td>
                  <td>10-01-21</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>  */}
    </div>

    <pre></pre> 
  </section>
  <Footer></Footer>
</div>
          
    </div>
  )
}

export default Dashboard