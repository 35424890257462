import React, { useState, useEffect } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { useAdminSettingsQuery, useGlobalSettngsQuery, useTokenContributionMutation } from "./store/ReduxApis"
import toast, { Toaster } from 'react-hot-toast';






function EmailSettings() {

  const token = useAdminSettingsQuery();
  const [maximum, setMaximum] = useState("")
  const [minimum, setMinimum] = useState("")
  const [price , setPrice] = useState("")
  const [saleid, setSaleid] = useState("")
  const [sale, setSale] = useState("")

  const [createPost, responseInfo] = useTokenContributionMutation();

  const newPost = {
    "contribution": true,  
    "min_contribution": minimum,
    "max_contribution": maximum,
    "price": price,
    "sale_id": saleid,
    "sale": sale
  }


  console.log(typeof(sale), typeof(price), "800")

  const tokenValue = (e) => {
    e.preventDefault()

    // if ((Number(sale) === 0) !== (Number(price) === 1000))  {
    //   alert('Please set price Value 1000 and Sale should be 0')
    //   return false;
    // } else if ((Number(sale) === 1) !== (Number(price) === 500))  {
    //   alert('Please set price Value 500 and Sale should be 1')
    //   return false;
    // } else if((Number(sale) === 2) !== (Number(price) === 250))  {
    //   alert(`Please set price Value 250 and Sale should be 2`)
    //   return false;
    // } 
    

 

    e.preventDefault();
    createPost(newPost)
  }

  console.log(responseInfo)

  const min = token?.data?.settings?.min_contribution;
  const max = token?.data?.settings?.max_contribution;
  const bnb = token?.data?.settings?.price
  const id = token?.data?.settings?.sale_id
  const sal = token?.data?.settings?.sale

  useEffect(() => {

   
    
  setMaximum(max);
  setMinimum(min);
  setPrice(bnb);
  setSaleid(id);
  setSale(sal)
    
    
  }, [])

  useEffect(() => {
    
   
      if (responseInfo?.data?.status === "Success") {
        toast(responseInfo?.data?.message)
      } 
    
  }, [responseInfo])

  return (

    <section className="content pages">
 <Toaster />

<div className='mainPage'>
         <h3>Token Contribution Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
            
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
            </div>
            {/* <div className='icons'>
                      <i class="fas fa-bullhorn"></i>
          <li><Link className='link' to="/market">Market Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-key"></i>
          <li><Link className='link' to="/product">Product Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fab fa-dropbox"></i>
          <li><Link className='link' to="/airdrop">Airdrop Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-user-friends"></i>
          <li><Link className='link' to="/team">Team Wallet Status</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-anchor"></i>
          <li><Link className='link' to="/operation">Operation Wallet Status</Link></li>
                      </div> */}


          </div>
          </div>
         
          <div style={{ width: "60%" }}>
                  <div className='box box-info'>
                      <h5 className="title">Token Contribution Settings</h5>
                      <div className='disclaimer'>
                        <h6>Presale Conditions</h6>
                        <div>
                          <label>1St Round</label>
                <p>Price = { bnb } Sale = 0</p>
              </div>
              <div>
                          <label>2nd Round</label>
                <p>Price = 32500 Sale = 1</p>
              </div>
              <div>
                          <label>3rd Round</label>
                <p>Price = 10833.3 Sale = 2</p>
                        </div>

                      </div>
                      <div className='setting'>
                          <div className="path">
                              <label>Minimum Amount</label>
                              <input type="text" value={minimum} onChange={(e) => setMinimum(e.target.value)}></input>
                          </div>
                          <div className="path">
                              <label>Maximum Amount</label>
                              <input type="text" value={maximum} onChange={(e) => setMaximum(e.target.value)}></input>
              </div >
              <div className="path">
                              <label>Price</label>
                              <input type="text" style={{ marginLeft: "110px" }} value={price} onChange={(e) => setPrice(e.target.value)}></input>
                          </div >
                          {/* <div className="path">
                              <label>Sale_ID</label>
                              <input type="text" style={{ marginLeft: "90px" }} value={saleid} onChange={(e) => setSaleid(e.target.value)}></input>
                          </div > */}
                          <div className="path">
                              <label>Sale</label>
                              <input type="text5" style={{ marginLeft: "120px" }} value={sale} onChange={(e) => setSale(e.target.value)}></input>
                          </div>
                       
                       
                          
                      </div>
                      <button className='btn btns' onClick={(e) => tokenValue(e)}>Submit</button>
                  </div>
            
      
    </div>

</div>

</section>
   
  )
}

export default EmailSettings