import React, { useEffect, useState } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { useChangePasswordMutation } from './store/ReduxApis';
import toast, { Toaster } from 'react-hot-toast';






function PasswordSettings() {

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [createPost, responseInfo] = useChangePasswordMutation()

  const newPost = {
    "email": email,
    "password": password
  }

  const token = (e) => {
    e.preventDefault()
    createPost(newPost);


  }

  useEffect(() => {

    if (responseInfo?.data?.status === "success") {
      toast(responseInfo?.data?.message)
     
  } 

  }, [responseInfo])

  console.log(responseInfo)




  return (

    <section className="content pages">
      <Toaster />

<div className='mainPage'>
         <h3>Password Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
            
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
            </div>
            {/* <div className='icons'>
                      <i class="fas fa-bullhorn"></i>
          <li><Link className='link' to="/market">Market Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-key"></i>
          <li><Link className='link' to="/product">Product Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fab fa-dropbox"></i>
          <li><Link className='link' to="/airdrop">Airdrop Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-user-friends"></i>
          <li><Link className='link' to="/team">Team Wallet Status</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-anchor"></i>
          <li><Link className='link' to="/operation">Operation Wallet Status</Link></li>
                      </div> */}


          </div>
          </div>
         
          <div style={{ width: "60%" }} >
                  <div className='box box-info'>
                      <h5 className="title">Password Settings</h5>
                      <div className='setting'>
                          <div className="path">
                              <label>Email</label>
                              <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder='Please Enter your email' style={{marginLeft: "30px"}}></input>
                          </div>
                          <div className="path">
                              <label>Password</label>
                              <input placeholder='please Enter a password' type="text" onChange={(e) => setPassword(e.target.value)}></input>
                          </div >
                         
                          
                      </div>
                      <button className='btn btns' onClick={(e) => token(e)}>Submit</button>
                  </div>
            
      
    </div>

</div>

</section>
   
  )
}

export default PasswordSettings