import React, { useState } from 'react'
import Footer from './Footer';
import { useRevertLockHistoryQuery } from './store/ReduxApis'
import { TablePagination } from '@material-ui/core';


function RevertLockHistory() {


  const revert = useRevertLockHistoryQuery();

  const [userAddress, setUserAddress] = useState("")
  const [second, setSecond] = useState("")
  const [searchTerm, setSerchTerm] = useState("")
  const [third, setThird] = useState("")
  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

    console.log(revert);

  return (
      <div>
       <div class="content-wrapper">
  
  <section class="content-header">
    <div class="container-fluid">
     
      <div class="row mb-2 text">
        <div class="col-sm-6">
          <h1>
            Revert Lock History List</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Revert Lock History List</li>
          </ol>
        </div>
      </div>
    </div>
  </section>

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header cards">
              <h3 class="card-title">Get all Revert Lock History List</h3>
              <div class="box-tools">
                <button type="button" class="btn btn-md btn-primary  pull-right" 
                  >
                  <i class="fa fa-refresh"></i> Rerender
                </button>
              </div>
            </div>

            <div class="card-body">
                    <div class="table-responsive">
                    <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {revert?.data?.data.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 30]}
 component="div"
 count={revert?.data?.data.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                <table id="example" class="table table-bordered table-striped table-hover" datatable
                >
                  <thead>
                    <tr>
                      
                      <th>User Address</th>
                      <th>Source Amount</th>
                      <th>Target Amount</th>
                      <th>Type</th>
                                                      <th>Status</th>
                                                
                    </tr>
                  </thead>
                  <tbody>
                                                  
                                                      
{  revert && revert?.data?.data.filter((value) => {
  if (searchTerm === "" && userAddress === "" && second === "" && third === "") {
    return value
  } else if (value.to.toLowerCase().includes(searchTerm.toLowerCase()) && value.sourceAmount.toLowerCase().includes(userAddress.toLowerCase()) && value.targetAmount.toLowerCase().includes(second.toLowerCase()) && value.type.toLowerCase().includes(third.toLowerCase())) {
    return value
  } 
}).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                                        <tr className='tr'>
                                                      
                                                              <td>{e.to}</td>
                                                        <td> {e.sourceAmount} MDTX</td>
                                                        <td> {e.targetAmount} MDTX</td>
                                                              <td>{e.type}</td>
                                                        <td>
                                                           <b>
                                                            <span class="badge text-white badge-success">Completed</span>
                                                          </b> 
                                                        
                                                        </td>
                                                      </tr>
                                                      )) 
                   }
                  </tbody>
                  
                  <tfoot>
                    <tr>
                      <th style={{width: "10%"}}><input type="text" onChange={(e) => setSerchTerm(e.target.value)} class="form-control" autocomplete="off"
                          placeholder="Search" /></th>
                      <th style={{width: "10%"}}><input type="text" onChange={(e) => setUserAddress(e.target.value)} class="form-control" autocomplete="off"
                          placeholder="Search" /></th>
                      <th style={{width: "10%"}}><input type="text" onChange={(e) => setSecond(e.target.value)} class="form-control" autocomplete="off"
                          placeholder="Search" /></th>
                      <th style={{width: "10%"}}><input type="text" class="form-control" onChange={(e) => setThird(e.target.value)} autocomplete="off"
                          placeholder="Search" /></th>
                      <th style={{width: "10%"}}><input type="text" class="form-control" autocomplete="off"
                          placeholder="Search" /></th>
                  
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </section>
        <Footer></Footer>
</div>   
      </div>
  )
}

export default RevertLockHistory