import React, { useEffect, useState } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap'
import { useAdminSettingsQuery, useUpdateLinkMutation } from './store/ReduxApis';
import { Toaster, toast } from 'react-hot-toast';






function Status() {

  const [facebook, setFaceboook] = useState("")
  const [github, setGithub] = useState("")
  const [linkldn, setLinkldn] = useState("")
  const [telegram, setTelegram] = useState("")
  const [twitter, setTwitter] = useState("")
  const [site, setSite] = useState("")

  const [createPost, responseInfo] = useUpdateLinkMutation();

  const data = useAdminSettingsQuery();

  console.log(data);
  console.log(responseInfo);

  const newPost = {
    "facebook": facebook,
    "github": github,
    "linkedIn": linkldn,
    "telegram": telegram,
    "twitter": twitter,
    "siteLinks": site
  }

  const updateLink = (e) => {
    e.preventDefault();
    createPost(newPost)

  }

  const face = data?.data?.settings?.socialMedia?.facebook
  const git = data?.data?.settings?.socialMedia?.github
  const link = data?.data?.settings?.socialMedia?.linkedIn
  const tele = data?.data?.settings?.socialMedia?.telegram
  const twit = data?.data?.settings?.socialMedia?.twitter
  const sites = data?.data?.settings?.socialMedia?.siteLinks

  console.log(face)

  useEffect(() => {

   


    setFaceboook(face);
    setGithub(git);
    setLinkldn(link);
    setTelegram(tele);
    setTwitter(twit);
    setSite(sites)

  }, [])

  useEffect(() => {
    if (responseInfo?.data?.status === "Success") {
      toast(responseInfo?.data?.message)
     
  } 
  }, [responseInfo])


  return (

    <section className="content pages">
      <Toaster />

<div className='mainPage'>
         <h3>Social Media Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
            
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
            </div>
            {/* <div className='icons'>
                      <i class="fas fa-bullhorn"></i>
          <li><Link className='link' to="/market">Market Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-key"></i>
          <li><Link className='link' to="/product">Product Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fab fa-dropbox"></i>
          <li><Link className='link' to="/airdrop">Airdrop Wallet Transactions</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-user-friends"></i>
          <li><Link className='link' to="/team">Team Wallet Status</Link></li>
                      </div>
                      <div className='icons'>
                      <i class="fas fa-anchor"></i>
          <li><Link className='link' to="/operation">Operation Wallet Status</Link></li>
                      </div> */}


          </div>
          </div>
         
  
           
              
            <div style={{ width: "60%" }}>
                  <div className='box box-info'>
                      <h5 className="title">Social Media Link</h5>
                      <div className='setting'>
                      <div className="path">
                              <label>Site Link</label>
                              <input type="text" value={site} onChange={(e) => setSite(e.target.value) } style={{ marginLeft: "-20px" }}></input>
                          </div>
                          <div className="path">
                              <label>Facebook</label>
                              <input type="text" value={facebook} onChange={(e) => setFaceboook(e.target.value) } style={{ marginLeft: "-20px" }}></input>
                          </div>
                          <div className="path">
                              <label>Discord</label>
                              <input type="text" value={github}  onChange={(e) => setGithub(e.target.value) } ></input>
                          </div >
                          <div className="path">
                              <label>Linkedin</label>
                              <input type="text" value={linkldn} onChange={(e) => setLinkldn(e.target.value) } style={{ marginLeft: "-20px" }}></input>
                          </div>
                          <div className="path">
                              <label>Telegram</label>
                              <input type="text" value={telegram}  onChange={(e) => setTelegram(e.target.value) } style={{ marginLeft: "-20px" }}></input>
                         </div>
                        <div className="path">
                              <label>Twitter</label>
                              <input type="text" value={twitter}  onChange={(e) => setTwitter(e.target.value) }></input>
                          </div>
                          
                      </div>
                      <button className='btn btns' onClick={(e) => updateLink(e)}>Submit</button>
                  </div>
            </div>

            
      
     
</div>

</section>
   
  )
}

export default Status;