import React, { useState, useEffect } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png';
import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAddAirdropWalletMutation, useAddMarketWalletMutation, useGlobalSettngsQuery, useMarketWalletDistrubutionQuery } from "./store/ReduxApis"
import Footer from "./Footer"
import { Toaster, toast } from 'react-hot-toast';
import { SpinnerRoundFilled } from 'spinners-react';






function WalletTransactions() {

    const token = useGlobalSettngsQuery();
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [startTime, setStartTime] = useState("")

    const [value, onChange] = useState(new Date());
    const [loading, setLoading] = useState(false);
 
    
    const [createPost, responseInfo] = useAddAirdropWalletMutation();

    console.log(responseInfo);

   
    


  const min = token?.data?.data[0]?.min_contribution;
  const max = token?.data?.data[0]?.max_contribution;
    const bnb = token?.data?.data[0]?.referralBonus
    
    console.log(value)

    const timestamp = Date.parse(value) / 1000 ;
    console.log(timestamp)

    const newPost = {
        "type": "airdrop",
        "data": [
         {
             "account": account,
             "amount": amount,
             "startTime": timestamp
         }
         
     ]
    }

    const addWallet = (e) => {
        e.preventDefault()
        setLoading(true);
    
        createPost(newPost)
    }

    useEffect(() => {
        if (responseInfo?.data?.status === "success") {
            toast("Succefully Added Airdrop Token") 
            setLoading(false);    
        } else if(responseInfo?.error?.data?.error === "Returned error: execution reverted: start time should be > current time")  {
            toast("start time should be greater than current time") 
            setLoading(false);  
        } else if (responseInfo?.error?.data?.status === "fail") {
            toast(`${responseInfo?.error?.data?.message}`)  
            setLoading(false);
        } 
      
    }, [responseInfo])
    

  return (
    <div>
       <div>
        <Toaster />
  <div class="content-wrapper extra">
<div class="row">
<div class="col-md-8 offset-2">
    <div class="card  card-primary">
        <div class="card-header">
            <h3 class="card-title">Add Airdrop Token</h3>
        </div>
        <form>
            <div class="card-body">
                <div class="form-group">
                    <label className='label'> Account :</label>
                    <input  type="text"  onChange={(e) => setAccount(e.target.value)}
                        class="form-control input" />

                </div>
                <div class="form-group">
                    <label className='label'> Amount :</label>
                    <input type="text input" formControlName="chainid" onChange={(e) => setAmount(e.target.value)}
                        class="form-control input" />
                </div>
                <div class="form-group">
                    <label className='label'> Start Time :</label>
                    <DateTimePicker onChange={onChange} value={value} />
                </div>

            </div>
            <div class="card-footer">
                <button type="submit"  onClick={(e) => addWallet(e)}  className={loading === true ? "changed" : "changedColor"}
                    style={{height: "34px", width:"100px"}}>Submit</button>
            </div>
         
        </form>
    </div>
</div>
</div>
<Footer></Footer>
</div>
</div> 
   
    </div>
   
  )
}

export default WalletTransactions;