import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';


const baseUrl = `https://apiadmin.elysiumlila.com/`;
const token = localStorage.getItem('nugenToken')
const cryptoApiHeaders = {
    "Content-Type": "application/json",
    "Accept": 'application/json',
    
};
  



export const getUserDetails = createApi({
    reducerPath: 'Dashboard',
    baseQuery: fetchBaseQuery({ baseUrl }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: () => ({
                url: `/admin/dashboard`,
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": 'application/json',
                    "Authorization": token,
                }
            })
        }),

        createPost: builder.mutation({
            query: (newPost) => {
              return {
                url: `/admin/auth/authentication`,
                method: 'POST',
                body: newPost,
                headers: {
                  "Content-Type": "application/json",
                  "Accept": 'application/json',
                 
                }
              }
    
            }
        }),
        verifytofa: builder.mutation({
            query: (newPost) => {
              return {
                url: `/admin/verifyOtp`,
                method: 'POST',
                body: newPost,
                headers: {
                  "Content-Type": "application/json",
                  "Accept": 'application/json',
                }
              }
    
            }
        }),
        dashboard: builder.query({
          query: () => ({
            url: `/admin/dashboard`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),

        adminList: builder.query({
          query: () => ({
            url: `/admin/getAdminLists`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        userWallet: builder.query({
          query: () => ({
            url: `/admin/data/filterWalletBalances`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),

        userHistory: builder.query({
          query: () => ({
            url: `/admin/data/filterUserHistory`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),

        lockTransactions: builder.query({
          query: () => ({
            url: `/admin/transaction/sentlocktokenList`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        revertLockHistory: builder.query({
          query: () => ({
            url: `/admin/transaction/sentRevertlocktokenlist`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        networkDetails: builder.query({
          query: () => ({
            url: `/admin/settings`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        buyTransactions: builder.query({
          query: () => ({
            url: `/admin/transaction/buytransactionList`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        getLock: builder.query({
          query: () => ({
            url: `/admin/gettokenlocks`,
            method: 'GET',
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        addUser: builder.mutation({
          query: (newPost) => ({
            url: `/admin/addAdminUser`,
            method: 'POST',
            body: newPost,
            headers: {
              "Content-Type": "application/json",
              "Accept": 'application/json',
              "Authorization": token,
            }
          })
        }),
        deleteUser: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/deleteAdminUser`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
               
              }
            }
  
          }
        }),
        globalSettngs: builder.query({
          query: () => {
            return {
              url: `/admin/globalSettings`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
               
              }
            }
  
          }
        }),
        adminSettings: builder.query({
          query: () => {
            return {
              url: `/admin/settings`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
               
              }
            }
  
          }
        }),
        emailTemplate: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateEmailTemplates`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                
              
               
              }
            }
  
          }
        }),
        marketWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=operational`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        partnershipWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=partnership`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        airDropWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=airdrop`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        productWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=airdrop`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        operationWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=advisory`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        teamWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=rewardpool`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        platformWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=rewardpool`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        reserveWalletDistrubution: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getDistributionWalletTx?type=reserve`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        addMarketWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         addProductWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         addTeamWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         addAirdropWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         
         addpartnershipWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         addOperationWallet: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/addDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         burnList: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getBurnTx`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         burnInfo: builder.query({
          query: () => {
            return {
              url: `/admin/data/burnInfo`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         addBurn: builder.mutation({
          query: () => {
            return {
              url: `/admin/data/burn`,
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         
         updateLink: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateLinks`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         
         contractUpdate : builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateSmartContractAddress`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         
         tokenContribution: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateTokenContribution`,
              method: 'POST',
              body: newPost,
              headers: { 
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         usersList: builder.query({
          query: () => {
            return {
              url: `/admin/settingupdate`,
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),
         
             
         updateAdminAccount : builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateAdminAccountDetails`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
         }),

         changePassword: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/changePaassword`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        marketRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=operational`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
        }),
        partnershipRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=partnership`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
        }),
        
        airdropRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=airdrop`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
        }),
        TeamRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=rewardpool`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              }
            }
  
          }
        }),
        OperationRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=advisory`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        ProductRevoke: builder.query({
          query: () => {
            return {
              url: `/admin/transaction/getRevokeDistributionWalletTx?type=reserve`,
              method: 'GET',
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        MarketAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        partnershipAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        ProductAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        TeamAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        OperationAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        AirdropAddRevoke: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/revokeDistributionWallet`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        networkDetails: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updatenetworkdetails`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),
        reserveLock: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/data/reserve`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                "Authorization": token,
              
               
              }
            }
  
          }
        }),

        emailSettings: builder.mutation({
          query: (newPost) => {
            return {
              url: `/admin/updateEmailSettings`,
              method: 'POST',
              body: newPost,
              headers: {
                "Content-Type": "application/json",
                "Accept": 'application/json',
                
              
               
              }
            }
  
          }
        }),

        referralGetData: builder.query({
          query: () => ({
              url: `/admin/getReferelHx`,
              method: 'GET',
              headers: {
                  "Content-Type": "application/json",
                  "Accept": 'application/json',
              }
          })
      }),
        
        
    })
})

export const { useCreatePostMutation, useVerifytofaMutation, useDashboardQuery, useAdminListQuery, useUserWalletQuery, useUserHistoryQuery, useLockTransactionsQuery, useRevertLockHistoryQuery, useNetworkDetailsQuery, useBuyTransactionsQuery, useGetLockQuery, useAddUserMutation, useDeleteUserMutation, useGlobalSettngsQuery, useAdminSettingsQuery, useEmailTemplateMutation, useMarketWalletDistrubutionQuery, useAirDropWalletDistrubutionQuery, useProductWalletDistrubutionQuery, useTeamWalletDistrubutionQuery, useOperationWalletDistrubutionQuery, useAddAirdropWalletMutation, useAddTeamWalletMutation, useAddOperationWalletMutation, useAddProductWalletMutation, useAddMarketWalletMutation, useBurnInfoQuery, useBurnListQuery, useAddBurnMutation, useTokenContributionMutation, useContractUpdateMutation, useUpdateLinkMutation , useUpdateAdminAccountMutation, useChangePasswordMutation, useMarketRevokeQuery, useAirdropRevokeQuery, useProductRevokeQuery, useTeamRevokeQuery, useOperationRevokeQuery, useMarketAddRevokeMutation, useTeamAddRevokeMutation, useProductAddRevokeMutation, useAirdropAddRevokeMutation, useOperationAddRevokeMutation, useNetworkDetailsMutation, useReserveLockMutation, useEmailSettingsMutation, useReserveWalletDistrubutionQuery, usePlatformWalletDistrubutionQuery, usePartnershipWalletDistrubutionQuery, useAddpartnershipWalletMutation, usePartnershipAddRevokeMutation, usePartnershipRevokeQuery, useReferralGetDataQuery } = getUserDetails