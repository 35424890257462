import React, { useState, useEffect } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png';
import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAddMarketWalletMutation, useGlobalSettngsQuery } from "./store/ReduxApis"
import Footer from "./Footer"
import {
    toast , Toaster
} from 'react-hot-toast';






function WalletTransactions() {

    const token = useGlobalSettngsQuery();
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [startTime, setStartTime] = useState("")

    const [value, onChange] = useState(new Date());
    
    const [createPost, responseInfo] = useAddMarketWalletMutation();

    console.log(responseInfo);

   
    


  const min = token?.data?.data[0]?.min_contribution;
  const max = token?.data?.data[0]?.max_contribution;
    const bnb = token?.data?.data[0]?.referralBonus
    
    console.log(value)

    const timestamp = Date.parse(value) / 1000;
    console.log(timestamp)

    const newPost = {
        "type": "market",
        "data": [
         {
             "account": account,
             "amount": amount,
             "startTime": timestamp
         }   
     ]
    }

    const addWallet = (e) => {
        e.preventDefault()
        createPost(newPost)
    }

    useEffect(() => {
        if (responseInfo?.data?.status === "success") {
            toast("Succefully Added")    
        }
      
    }, [responseInfo])
    
    

  return (
      <div>
          <Toaster />
    <div class="content-wrapper extra">
<div class="row">
  <div class="col-md-8 offset-2">
      <div class="card  card-primary">
          <div class="card-header">
              <h3 class="card-title">Add Seed Token</h3>
          </div>
          <form>
              <div class="card-body">
                  <div class="form-group">
                      <label className='label'> Account :</label>
                      <input  type="text" formControlName="network" onChange={(e) => setAccount(e.target.value)}
                          class="form-control input" />
  
                  </div>
                  <div class="form-group">
                      <label className='label'> Amount :</label>
                      <input type="text input" formControlName="chainid" onChange={(e) => setAmount(e.target.value)}
                          class="form-control input" />
                  </div>
                  <div class="form-group">
                      <label className='label'> Start Time :</label>
                      <DateTimePicker onChange={onChange} value={value} />
                  </div>
  
              </div>
              
              <div class="card-footer">
                  <button type="submit" onClick={(e) => addWallet(e)} class="btn btn-md btn-primary btn-block"
                      style={{height: "34px", width:"100px"}}>Submit</button>
              </div>
          </form>
      </div>
  </div>
</div>
<Footer></Footer>
</div>
</div>
   
  )
}

export default WalletTransactions;