import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.png";
import Logintofa from './Logintofa';


import { useCreatePostMutation } from './store/ReduxApis'

import { useAdminSettingsQuery } from './store/ReduxApis';
import toast, { Toaster } from 'react-hot-toast';



function Login() {

  const [username, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [createPost, responseInfo] = useCreatePostMutation()
  const adminSettings = useAdminSettingsQuery();
  const [showPassword, setShowPassword] = useState(null);

    
    console.log(adminSettings)
    

    const logoImg = adminSettings?.data?.settings?.emailTemplate?.logo;
    console.log(logoImg)


  
  console.log(responseInfo.status)
  
  const newPost = {
     "email": username,
     "password": password
  }

  useEffect(() => {
   if(responseInfo?.error?.data?.status === "fail") {
       toast(responseInfo?.error?.data?.message)
    //   alert(responseInfo?.error?.data?.message)
   }
   

  }, [responseInfo])

  console.log(responseInfo)


  const loginClick = (e) => {
    e.preventDefault();
    createPost(newPost)

  }

  return (
    <div>
        <Toaster />
      { responseInfo.status === "fulfilled" ? <Logintofa logoImage={logoImg}> </Logintofa> : <div class="login-box">
    <div class="login-logo">
          <img class="mb-3 "  src={logo} alt="" /> <b> | ADMIN </b> 
      
    </div>
    <div class="card">

        
        <div class="card-body login-card-body">
          
            <p class="login-box-msg">Sign in to start your session</p>

            <form>
                <div class="form-group">

                    <div class="input-group">
                        <input type="text" formControlName="email" class="form-control" onChange={(e) => setUserName(e.target.value)} placeholder='Email'/>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                        <div class="error invalid-feedback">
                            <div></div>
                            <div>Email must be a valid email address</div>
                        </div>
                    </div>
                </div>

             

                <div class="input-group">
                        <input formControlName="password" type={showPassword ? 'text' : 'password'} onChange={(e) => setPassword(e.target.value)} class="form-control" placeholder='Password' />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                      <span onClick={() => setShowPassword(!showPassword)} className="eyeic"> { !showPassword ? <i className="fas fa-eye-slash"></i> : <i class="fa fa-eye"></i>} </span>          
                
                            </div>
                        </div>
                        
                    </div>
                    <div  class="text text-danger">
                        <div></div>
                    </div>

                <div class="btnMain">
                    
                        <button type="submit" class="btn btn-md btn-primary btn-block" onClick={(e) => loginClick(e)}>Login</button>
                   
                </div>
            </form>
            {/* <div className='items'>
            <p class="mb-1">
               <a href="javascript:void(0);">I forgot my password</a> 
            </p>
            <p class="mb-0">
               <a routerLink="/auth/register" class="text-center">Create an account</a> 

            </p>

            </div> */}
          
         
        </div>
        
        </div>
      </div>}
      </div>

  )
}

export default Login