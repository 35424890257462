import React, { useState } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png';
import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAddAirdropWalletMutation, useAddBurnMutation, useAddMarketWalletMutation, useBurnInfoQuery, useGlobalSettngsQuery, useMarketWalletDistrubutionQuery } from "./store/ReduxApis"
import Footer from "./Footer"






function WalletTransactions() {

    const token = useGlobalSettngsQuery();
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [startTime, setStartTime] = useState("")

    const [value, onChange] = useState(new Date());
    
    const [createPost, responseInfo] = useAddBurnMutation();

    console.log(responseInfo);

    const burnInfo = useBurnInfoQuery();


    console.log(burnInfo)

   
    


  const min = token?.data?.data[0]?.min_contribution;
  const max = token?.data?.data[0]?.max_contribution;
    const bnb = token?.data?.data[0]?.referralBonus
    
    console.log(value)

    const timestamp = Date.parse(value);
    console.log(timestamp)

    const newPost = {
        "type": "airdrop",
        "data": [
         {
             "account": account,
             "amount": amount,
             "startTime": timestamp
         }
         
     ]
    }

    const addWallet = (e) => {
        e.preventDefault()
        createPost(newPost)

    }
    

  return (
    <div>
          <div class="content-wrapper extra">
              <div>
                  <h6 className='burn'>Burn Amount: <span class="badge text-white badge-success">{burnInfo?.data?.burnInfo} </span></h6>
              </div>
<div class="row">
  <div class="col-md-8 offset-2">
      <div class="card  card-primary">
          <div class="card-header">
              <h3 class="card-title">Add Burn Token</h3>
          </div>
          <form>
             
              
              <div class="card-footer">
                 { burnInfo?.data?.burnInfo > 0 ? <button type="submit" onClick={(e) => createPost(e)} class="btn btn-md btn-primary btn-block"
                      style={{height: "34px", width:"100px"}}>Submit</button> :  <button type="submit" onClick={(e) => addWallet(e)} class="btn btn-md btn-primary btn-block"
                      style={{height: "34px", width:"100px"}} disabled>Submit</button>}
              </div>
          </form>
      </div>
  </div>
</div>
<Footer></Footer>
</div>
</div>
   
  )
}

export default WalletTransactions;