import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import user from "../assets/user.jpg";
import Settings from "./Settings";

import MenuItem from "./MenuItem";
import ConfigSettings from "./ConfigSettings";
import Dashboard from "./Dashboard";
import Login from "./Login";
import AdminList from "./AdminList";
import NetworkDetails from "./NetworkDetails";
import Logout from "./Logout";

import refreshPage  from "./Logout"
import { useAdminSettingsQuery } from "./store/ReduxApis";

import miniIcon from "../assets/favicon.ico"

/**
 * @author
 * @function SideMenu
 **/

// added more menuItems for testing

const label = () => {
  return () => {
    
  }
}

export const menuItems = [
 
  {
    name: "Dashboard",
    exact: true,
    to: "/",
    iconClassName: "bi bi-speedometer2",
    items: <Dashboard></Dashboard>
  },
  { name: "Admin List", to: `/design-2`, iconClassName: "fab fa-google-wallet", items: <AdminList></AdminList> },
  
  {
    name: "Contributor List",
    exact: true,
   
    iconClassName: "far fa-user",
    miniIcon: "fas fa-angle-left",
    subMenus: [
      { name: "User Wallet Info", to: "/content/userbalance" },
      // { name: "User History", to: "/content/userHistory" },
      // { name: "User Lock Transactions", to: "/content/lockedtransactions" },
      { name: "User Buy Transactions", to: "/content/buytransactions" },
      { name: "User Referral Transactions", to: "/content/referralTransactions" },

    ],
  },
  {
    name: "Transactions",
    exact: true,
   
    iconClassName: "fas fa-burn",
    miniIcon: "fas fa-angle-left",
    subMenus: [
      { name: "Operational Transactions", to: "/content/markettransactions" },
      // { name: "Seed Transactions", to: "/content/seedtransactions" },
      { name: "Reward Pool Transactions", to: "/content/teamTransactions" },
      { name: "Airdrop Transactions", to: "/content/ecosystemTransactions" },
      { name: "Advisory Transactions", to: "/content/advisoryTransactions" },
      { name: "Reserve Transactions", to: "/content/reserveTransactions" },
      { name: "Partnership Transactions", to: "/content/partnershiptransactions" }
  
    ],
  },

  { name: "Network Details", to: `/network`, iconClassName: "fas fa-wallet" },
  {
    name: "Token Add Distribution",
    exact: true,
    iconClassName: "fab fa-bitcoin",
    miniIcon: "fas fa-angle-left",
    subMenus: [
      { name: "Operational Token", to: "/market" },
      // { name: "Seed Token", to: "/seed" },
      { name: "Reward Pool Token", to: "/team" },
      { name: "Airdrop Token", to: "/ecosystem" },
      { name: "Advisory Token", to: "/advisory" },
      { name: "Reserve Token", to: "/reserve" },
      { name: "Partnership Token", to: "/partnership" },
   
   
    ],
  },
  {
    name: "Revoke Transaction Wallet",
    exact: true,
    iconClassName: "fas fa-adjust",
    miniIcon: "fas fa-angle-left",
    subMenus: [
      { name: "Operational Revoke", to: "/marketRevoke" },
      { name: "Reward Pool Revoke", to: "/teamRevoke" },
      { name: "Airdrop Revoke", to: "/ecosystemRevoke" },
      { name: "Advisory Revoke", to: "/advisoryRevoke" },
      // { name: "Seed Revoke", to: "/seedRevoke" },
      { name: "Reserve Revoke", to: "/reserveRevoke" },
      { name: "Partnership Revoke", to: "/partnershipRevoke" },
   
   
    ],
  },
  {
    name: "Revoke Add Distribution",
    exact: true,
    iconClassName: "fab fa-ubuntu",
    miniIcon: "fas fa-angle-left",
    subMenus: [
      { name: "Operational Add Revoke", to: "/marketAddRevoke" },
      { name: "Airdrop Add Revoke", to: "/ecosystemAddRevoke" },
      { name: "Reward Pool Add Revoke", to: "/teamAddRevoke" },
      { name: "Advisory Add Revoke", to: "/advisoryAddRevoke" },
      // { name: "Seed Add Revoke", to: "/seedAddRevoke" },
      { name: "Reserve Add Revoke", to: "/reserveAddRevoke" },
      { name: "Partnership Add Revoke", to: "/partnershipAddRevoke" }
   
    ],
  },
 
  { name: "Settings", to: `/design-3`, iconClassName: "fas fa-cog", items: <Settings></Settings> },

  



 
];

const SideMenu = (props) => {
  const [inactive, setInactive] = useState(false);

  const settings = useAdminSettingsQuery();

  console.log(settings)

  
  const logoImg = settings?.data?.settings?.emailTemplate?.logo;
  console.log(logoImg, "logoImg")

  const emailName = settings?.data?.settings?.mailingAddress;



  const close = () => {
    localStorage.removeItem("nugenToken");
    window.location.reload(false);
  }

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu();
    }

    props.onCollapse(inactive);
  }, [inactive]);

  //just an improvment and it is not recorded in video :(
  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  /*just a little improvement over click function of menuItem
    Now no need to use expand state variable in MenuItem component
  */
  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        console.log(next);
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });
  }, []);

  return (
    <div className={`side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section">
        { !inactive ?  <div >
          <img src={logo} className="logoImg" alt="webscript" />
        </div> : <div >
          <img src={miniIcon} style={{margin: "0 10px", width: "30px"}} alt="webscript" />
        </div> }
        <div onClick={() => setInactive(!inactive)} >
          {inactive ? (
                  <div className="toggle-menu-close">
                  <i class="bi bi-arrow-left-square-fill"></i>
                   </div>
          ) : (
               <div className="toggle-menu-btn">
                <i class="fas fa-bars"></i>
                </div>
          )}
        </div>
      </div>

      <div className="search-controller">
        <div>
          {!inactive ? <p className="emailName">{emailName}</p> : "" }  
         
        </div>
      
        
      </div>

      <div className="divider"></div>

      <div className="main-menu">
        <ul>
          {menuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              miniIcon={menuItem.miniIcon}
              onClick={(e) => {
                if (inactive) {
                  setInactive(false);
                }
              }}
              
            />
            
          ))}

          {/* <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-speedometer2"></i>
              </div>
              <span>Dashboard</span>
            </a>
          </li>
          <MenuItem
            name={"Content"}
            subMenus={[{ name: "Courses" }, { name: "Videos" }]}
          />
          <li>
            <a className="menu-item">
              <div className="menu-icon">
                <i class="bi bi-vector-pen"></i>
              </div>
              <span>Design</span>
            </a>
          </li> */}
        </ul>
       { !inactive ?  <div className="logout" onClick={() => close()}>
        <li> <i class="fas fa-sign-out-alt"></i> logout </li>
       </div> :  <div className="logout" onClick={() => close()}>
         <i class="fas fa-sign-out-alt"></i>
       </div> }
      </div>

     
    </div>
  );
};

export default SideMenu;
