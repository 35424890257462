import React, { useEffect, useState } from 'react'
import Footer from './Footer';
import { useAdminSettingsQuery, useNetworkDetailsMutation, useNetworkDetailsQuery } from './store/ReduxApis';
import toast, { Toaster } from 'react-hot-toast';



function NetworkDetails() {


    const data = useAdminSettingsQuery();

    const [createPost, responseInfo] = useNetworkDetailsMutation()
    

    const [networkName, setNetworkName] = useState("")
    const [chain, setChain] = useState("");
    const [symbol, setSymbol] = useState("");
    const [decimal, setDecimal] = useState("");
    const [rpcurl, setRpcurl] = useState("");
    const [block, setBlock] = useState("");
    const [loading, setLoading] = useState("")

    console.log(data);

    useEffect(() => {
        setNetworkName(data?.data?.settings?.network);
        setChain(data?.data?.settings?.chainid)
        setSymbol(data?.data?.settings?.symbol)
        setDecimal(data?.data?.settings?.decimals)
        setRpcurl(data?.data?.settings?.rpcUrl)
        setBlock(data?.data?.settings?.blockExplorerUrls)

    }, [])

    const newPost = {
        "chainid": chain,
        "network": networkName,
        "symbol": symbol,
        "decimals": decimal,
        "rpcUrl": rpcurl,
        "blockExplorerUrls": block
    }
   
    const details = (e) => {
        e.preventDefault()
        setLoading(true)
        createPost(newPost);
    }

    console.log(responseInfo)


    useEffect(() => {
        if (responseInfo?.data?.status === "Success") {
            toast(responseInfo?.data?.message)
            setLoading(false)
           
        } 
    }, [responseInfo])
   


   

    




  return (
      <div>
           <Toaster />
          <div class="content-wrapper extra">
    <div class="row">
        <div class="col-md-8 offset-2">
            <div class="card  card-primary">
                <div class="card-header">
                    <h3 class="card-title">Network Details</h3>
                </div>
                <form>
                    <div class="card-body">
                        <div class="form-group">
                            <label className='label'> Network Name :</label>
                            <input  type="text" value={networkName} onChange={(e) => setNetworkName(e.target.value)} formControlName="network" 
                                class="form-control input" />
                        </div>
                        <div class="form-group">
                            <label className='label'> Chain ID :</label>
                            <input type="text input" value={chain} onChange={(e) => setChain(e.target.value)} formControlName="chainid"
                                class="form-control input" />
                        </div>
                        <div class="form-group">
                            <label className='label'>Currency Symbol :</label>
                            <input type="text input" value={symbol} onChange={(e) => setSymbol(e.target.value)} formControlName="chainid"
                                class="form-control input" />
                        </div>
                        <div class="form-group">
                            <label className='label'>Currency Decimal :</label>
                            <input type="text input" value={decimal} onChange={(e) => setDecimal(e.target.value)} formControlName="chainid"
                                class="form-control input" />
                        </div>
                        <div class="form-group">
                            <label className='label'> RpcUrl :</label>
                            <input type="text input" value={rpcurl} onChange={(e) => setRpcurl(e.target.value)} formControlName="chainid"
                                class="form-control input" />
                        </div>
                        <div class="form-group">
                            <label className='label'> BlockExplorerUrls :</label>
                            <input type="text input" value={block} onChange={(e) => setBlock(e.target.value)} formControlName="chainid"
                                class="form-control input" />
                        </div>
        
        
                    </div>
                    
                    <div class="card-footer">
                        <button type="submit" onClick={(e) => details(e)} className={loading === true ? "changed" : "changedColor"}
                            style={{height: "34px", width:"100px"}}>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <Footer></Footer>
</div>
    </div>
  )
}

export default NetworkDetails