import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.png";
import Spinner from "./Spinner";
import toast, { Toaster } from 'react-hot-toast';

import { SpinnerRoundFilled, SpinnerCircular } from 'spinners-react';

import { useAdminSettingsQuery, useVerifytofaMutation } from './store/ReduxApis';


function Logintofa({ logoImage }) {
    const [otp, setOtp] = useState("")
    const [username, setUserName] = useState("")

    const [createPost, responseInfo] = useVerifytofaMutation()
    const [loading, setLoading] = useState(true)

    const [spinnerLoading, setSpinnerLoading] = useState(false)



    console.log(responseInfo)

    const newPost = {

        "email": username,
        "otp": otp

    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    const settings = useAdminSettingsQuery();

    console.log(settings)
  
    
    const logoImg = settings?.data?.settings?.emailTemplate?.logo;
    

    
    const verifyOtp = (e) => {
        e.preventDefault();
        createPost(newPost);
        setSpinnerLoading(true)

        setTimeout(() => {
         
            setSpinnerLoading(false)
          }, 4000);
  
        //localStorage.setItem(responseInfo.data.token) 
    }

    useEffect(() => {
        console.log(responseInfo && responseInfo?.data)

        if(responseInfo?.error?.data?.message === "otp expired") {
            toast(responseInfo?.error?.data?.message)
        }
        
        if (responseInfo?.data?.status === "success") {
            setTimeout(function () { refreshPage(); }, 4000);
            localStorage.setItem("nugenToken", responseInfo?.data?.token); 
        }
       
        
       // const token = localStorage.setItem(responseInfo?.data?.token)
    }, [responseInfo])

    useEffect(() => {
        setTimeout(() => {
            setLoading(true);
          }, 4000);
    })

    



  return ( 
    <div>
        {loading === false ? <div class="page-loader">
            <SpinnerRoundFilled size={90} thickness={100} speed={100} color="rgba(59, 57, 172, 1)" />
            </div> :   <div class="login-box">
    <div class="login-logo">
                  <img class="mb-3 logo" src={logo} alt="" /> <b> | ADMIN </b> 
      
    </div>
    <div class="card">

        
        <div class="card-body login-card-body">
          
            <p class="login-box-msg">Verify 2fa </p>

            <form>
              

                          <h6 className='otp'>OTP Sent To Your Registered Mail ID</h6>
                          
                          <div class="form-group">

<div class="input-group">
    <input type="text" formControlName="email" class="form-control" onChange={(e) => setUserName(e.target.value)} placeholder='EMAIL'/>
    <div class="input-group-append">
        <div class="input-group-text">
            <span class="fas fa-envelope"></span>
        </div>
    </div>
    <div class="error invalid-feedback">
        <div></div>
        <div>Email must be a valid email address</div>
    </div>
</div>
</div>

                <div class="input-group">
                        <input type="text" formControlName="password" onChange={(e) => setOtp(e.target.value)} class="form-control" placeholder='ENTER OTP' />
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                        
                    </div>
                    <div  class="text text-danger">
                        <div></div>
                    </div>

                    { spinnerLoading ?    <div class="btnMain">
                    
                    <button class="btn btn-md btn-primary btn-block">
                    <SpinnerCircular size={25} thickness={300} speed={100} color="#ffffff" secondaryColor="rgba(0, 0, 0, 0.44)" />
                    </button>
               
            </div>  :    <div class="btnMain">
                    
                    <button type="submit" class="btn btn-md btn-primary btn-block" onClick={(e) => verifyOtp(e)}>Submit</button>
               
            </div>}

             
            </form>
            {/* <div className='items'>
            <p class="mb-1">
               <a href="javascript:void(0);">I forgot my password</a> 
            </p>
            <p class="mb-0">
               <a routerLink="/auth/register" class="text-center">Create an account</a> 

            </p>

            </div> */}
          
         
        </div>
        
        </div>
      </div> }
    
      </div>

  )
}

export default Logintofa