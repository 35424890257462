import React from 'react'
import Footer from './Footer';
import { useLockTransactionsQuery } from './store/ReduxApis'
import { TablePagination } from '@material-ui/core';


function LockTokeList() {

    const lockTransactions = useLockTransactionsQuery();

    console.log(lockTransactions);

    const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
    




  return (
      <div>
     <div class="content-wrapper">
    
    <section class="content-header">
        <div class="container-fluid">
           
            <div class="row mb-2">
                <div class="col-sm-6 text">
                    <h1>
                        Locked Token Transaction List</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Locked Token Transaction List</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>



    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header cards">
                            <h3 class="card-title">Get all the Locked Token Transaction details</h3>
                            <div class="box-tools">
                                <button type="button" class="btn btn-md btn-primary  pull-right">
                                    <i class="fa fa-refresh"></i> Rerender
                                </button>
                            </div>
                        </div>

                        
                        <div class="card-body">
                                      <div class="table-responsive">
                                      <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {lockTransactions?.data?.data?.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 30]}
 component="div"
 count={lockTransactions?.data?.data?.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                                          
                            <table id="example" class="table table-bordered table-striped table-hover" datatable
                                >
                                <thead>
                                    <tr>
                                        
                                        <th>User Address</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>Locked Until</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody>

                                                  {lockTransactions && lockTransactions?.data?.data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                                       <tr className='tr'>
                                                          
                                                       <td>{e.to}</td>
                                                     
                                                          <td> {e.targetAmount} MDTX</td>
                                                          <td>
                                                          <b>
                                                               <span class="badge text-white badge-success">Completed</span>
                                                           </b> 
                                                        
                                                        
                                                              
                                                       </td>
               
                                                       <td>
                                                       {e.timestamp}
                                                       </td>
               
               
                                                       <td>
                                                           <a href="javascript:void(0);" 
                                                               tooltip="view" theme="dark" placement="left"
                                                               class="btn btn-xs btn-info view">View</a>
                                                           &nbsp;
                                                       </td>
                                                   </tr>
                                                      
                                                  ))}
                                   
                                </tbody>
                                 
                                <tfoot>
                                    <tr>
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Search Id" name="search-Id" /></th>
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Search User Address" name="search-useraddress" /></th>
                                        
                             
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Search Target Amount" name="Search Target Amount" /></th>
                                        <th><input type="text" class="form-control" autocomplete="off"
                                                placeholder="Search Status" name="search-Status" /></th>
                                                <th><input type="text" class="form-control" autocomplete="off"
                                                    placeholder="Search Status" name="search-Status" /></th>
                                          
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        </div>
                        
                    </div>
                     

                     
                </div>
                 
            </div>
             

        </div>
          
              </section>
              <Footer></Footer>
    
</div>     
      </div>
  )
}

export default LockTokeList