import React,{ useState, useEffect } from 'react'

import '../assets/main.css';
import '../assets/admin.css';

import Logo from '../assets/logos/logo.png'

import favIcon from '../assets/logos/favicon.png'
import { Link } from 'react-router-dom';
import { useAdminSettingsQuery, useEmailSettingsMutation } from './store/ReduxApis';






function EmailSettings() {

  const data = useAdminSettingsQuery();
  console.log(data)

  const [createPost, responseInfo] = useEmailSettingsMutation();

  
  const [host, setHost] = useState("")
  const [port, setPort] = useState("")
  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [audience, setAudience] = useState("")



  const newPost = {
    "settings" : {
      "audience": audience,
      "host": host,
      "password": password,
      "port": port,
      "user": user,
  }
  
   
  }

  const audiences = data?.data?.settings?.email?.audience;
  const ports = data?.data?.settings?.email?.port;
  const hosts = data?.data?.settings?.email?.host;
  const passwords = data?.data?.settings?.email?.password;
  const users = data?.data?.settings?.email?.user;

 
  useEffect(() => {
    
    setAudience(audiences);
    setPort(ports);
    setHost(hosts);
    setPassword(passwords);
    setUser(users)
   
  
  }, [])

  const tokenValue = (e) => {
    e.preventDefault(e)
    createPost(newPost)
  }

 
  



  return (

    <section className="content pages">

      <div className='mainPage'>
         <h3>Email Settings</h3>    
       </div>
         <div className="row">
        <div className="firstBox" >
          <div className='box box-info'>

          
        <div className="box-header with-border">
                <h5 className="title">Manage Settings</h5>
            </div>

          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/general">General</Link></li>
        
          </div>
          <div className='icons'>
          <i class="fas fa-lock"></i>
          <li><Link className='link' to="/emailSettings">Email Settings</Link></li>
            </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/email">Email Template Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-share-alt"></i>
          <li><Link className='link' to="/password">Password Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/account">Token Contribution Settings</Link></li>
          </div>
          <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/contractAddress">Contract Address Settings</Link></li>
            </div>
            <div className='icons'>
          <i class="fab fa-google"></i>
          <li><Link className='link' to="/details">Admin Details</Link></li>
          </div>
          <div className='icons'>
          <i class="fas fa-cog"></i>
          <li><Link className='link' to="/status">Social Media Link</Link></li>
          </div>


          </div>
          </div>
         
          <div className="col-md-8" style={{width: "60%"}}>
        <div className="box box-info">
            <div className="box-header with-border">
                <h5 className="title">Email Settings</h5>
            </div>

                      <form action="#" method="post" className="form-horizontal" enctype="multipart/form-data">
                          <div className="inputItems">
                              <label>Host</label>
                              <input type="text" className='input' value={host} onChange={(e) => setHost(e.target.value)}  style={{marginLeft: "35px"}}></input>
                          </div>
                          <div className="inputItems">
                              <label>Port</label>
                              <input type="text" className='input' value={port} onChange={(e) => setPort(e.target.value)} style={{marginLeft: "38px"}}></input>
                          </div>
                          <div className="inputItems">
                              <label>User</label>
                              <input type="text" className='input' value={user} onChange={(e) => setUser(e.target.value)} style={{marginLeft: "35px"}}></input>
                          </div>
                          <div className="inputItems">
                              <label>Password</label>
                              <input type="text" className='input' value={password} onChange={(e) => setPassword(e.target.value)}  style={{marginLeft: "-5px"}}></input>
                          </div>
                       
            </form>
            <button className='btn btns' onClick={(e) => tokenValue(e)}>Submit</button>
        </div>
    </div>

</div>

</section>
   
  )
}

export default EmailSettings