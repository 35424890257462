import React, { useEffect, useState } from 'react'
import { useAddUserMutation } from './store/ReduxApis';
import { useHistory } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import toast, { Toaster } from 'react-hot-toast';


function AddUser() {

    const [createPost, responseInfo] = useAddUserMutation();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const history = useHistory();

    const newPost = {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "password": password,
        "confirmPassword": confirmPassword
        
       
    } 

   
    console.log(responseInfo)

    const addUser = (e) => {
        
     
        e.preventDefault()
        createPost(newPost);
    }

    useEffect(() => {
        if (responseInfo?.data?.status === "success") {
            toast(responseInfo?.data?.message)
            setTimeout(() => (window.location.href = '/design-2'), 2000)
            
        } else if(responseInfo?.error?.data?.status === "fail") {
            toast(responseInfo?.error?.data?.message)
        }
         else if(responseInfo?.status === "fulfilled") {
            toast(responseInfo?.data?.message)
        }

    }, [responseInfo])
     

    


  return (
      <div>
          <Toaster />
          
          <div class="content-wrapper">

  <section class="content-header">
      <div class="container-fluid">
          <div class="row mb-2">
              <div class="col-sm-6 text">
                  <h1>Add Admin</h1>
              </div>
              <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active">Add Admin</li>
                  </ol>
              </div>
          </div>
      </div>
  </section>
  

  <section class="content">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-6 offset-md-2">
                 
                  <div class="card card-outline card-primary">
                  <form onSubmit={(e) => addUser(e)}>
                  <div class="card-body">
                  <div class="form-group">
  <input type = "text" class = "txtPost" placeholder = "First Name" name='firstName' onChange={(e) => setFirstName(e.target.value)} required />
  <input type = "text" class = "txtPost" placeholder = "Last Name" name='lastName' onChange={(e) => setLastName(e.target.value)} required />
  <input type = "text" class = "txtPost" placeholder = "Email" name='email' onChange={(e) => setEmail(e.target.value)} required />
  <input type = "text" class = "txtPost" placeholder = "Password" name='password' onChange={(e) => setPassword(e.target.value)} required />
  <input type = "text" class = "txtPost" placeholder = "Password Confirmation" name='confirmPassword' onChange={(e) => setConfirmPassword(e.target.value)} required />
  
  <button type='submit' class = "btnPost">Add Admin</button>
  </div>
  </div>
</form>
                      

                      {/* <form>
                          <div class="card-body">

                              <div class="form-group">
                                  <label>First Name</label>
                                  <input type="text" onChange={(e) => setFirstName(e.target.value)} formControlName="firstName" class="form-control"
                                       />

                                  <div>
                                      
                                    
                                  </div>
                              </div>

                              <div class="form-group">
                                  <label>Last Name</label>
                                  <input type="text" onChange={(e) => setLastName(e.target.value)} formControlName="lastName" class="form-control" required
                                     />

                                  <div>
                                      <div>Last Name is required</div>
                                  </div>
                              </div>

                              <div class="form-group">
                                  <label>Email</label>
                                  <input type="text" onChange={(e) => setEmail(e.target.value)} formControlName="email" class="form-control" required />

                                  <div>
                                      <div>Email is required</div>
                                  </div>
                              </div>

                              <div class="form-group">
                                  <label>Password</label>
                                  <input type="password" onChange={(e) => setPassword(e.target.value)} formControlName="password" class="form-control" required
                                      />

                                  <div >
                                      <div>Password is required</div>
                                  </div>
                              </div>

                              <div class="form-group">
                                  <label>Confirm Password</label>
                                  <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} formControlName="confirmPassword" class="form-control" required
                                       />

                                  <div>
                                      <div>Confirm Password is required</div>
                                  </div>
                              </div>


                          </div>
                          
                          <div class="card-footer">
                              <button type="submit" onClick={(e) => addUser(e)} class="btn btn-primary">Submit</button>
                          </div>
                      </form> */}
                  </div>

              </div>
              



          </div>

      </div>
      
  </section>
  
  


          </div>
          
    </div>
  )
}

export default AddUser