import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import { useUserWalletQuery } from './store/ReduxApis';
import ReactPaginate from 'react-paginate';
import uuid from 'react-uuid';
import { TablePagination } from '@material-ui/core';



function UserWallet() {

    const wallet = useUserWalletQuery();

    console.log(wallet)


    const [userAddress, setUserAddress] = useState("")
    const [second, setSecond] = useState("")
    const [searchTerm, setSearchTerm] = useState("")
    const [third, setThird] = useState("")



const [page, setPage] = React.useState(0);
const [rowsPerPage, setRowsPerPage] = React.useState(10);
const handleChangePage = (event, newPage) => {
  setPage(newPage);
};

const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
};
    
    

  
 


  return (
      <div>
          <div class="content-wrapper">
   
    <section class="content-header">
        <div class="container-fluid">
            
            <div class="row mb-2">
                <div class="col-sm-6 text">
                    <h1>User Wallet Info</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">User Wallet Info</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
  

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header cards">
                            <h3 class="card-title">Get all the user wallet details</h3>
                            <div class="box-tools">
                               
                            </div>
                        </div>

                     
                        <div class="card-body">
                                      <div class="table-responsive"> 
                                      <div className='pagination'>
                                                  <h6 className='page'>Show 1 to {wallet?.data?.wallet?.length} entries</h6>
                                                      <TablePagination
                                                          
  className='pagi'
 rowsPerPageOptions={[5, 10, 30]}
 component="div"
 count={wallet?.data?.wallet?.length}
 rowsPerPage={rowsPerPage}
 page={page}
 onChangePage={handleChangePage}
 onChangeRowsPerPage={handleChangeRowsPerPage}>

</TablePagination>
                                                  </div>
                        
                              <table id="example" class="table table-bordered table-striped table-hover" datatable
                              >
                                <thead>
                                    <tr>
                                       
                                        <th>User Wallet Address</th>
                                        <th>Refferal Code</th>
                                        <th>Date</th>
                                        <th>Referred By</th>
                                        
                                    </tr>
                                </thead>

                                              <tbody>
                                              {wallet && wallet?.data?.wallet?.filter((value) => {
    if (searchTerm === "" && userAddress === "") {
      return value
    } else if (value.user_wallet.toLowerCase().includes(searchTerm.toLowerCase()) && value.newcode.toLowerCase().includes(userAddress.toLowerCase())) {
      return value
    } 
  }).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(e => (
                                                       <tr className='tr'>
    
                                                        
                                                       <td>{e.user_wallet}</td>
                                                       <td>{e.newcode}</td>
                                                       <td>{new Date(Number(e.registerTime)).toLocaleDateString()}</td>
                                                       <td>{e.referredCode}</td>
          
                                               </tr>
                                                  ))}
                                            
                                                  
                                                  <td>

                                                      <div className='paginate'>
                                                      
                                                           {/* <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttn"}
          nextLinkClassName={"nextBttn"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
      /> */}
                                                      </div>
                                                      
                                                  </td>
                                                  
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th>
                                            <input type="text" class="form-control" onChange={(e) => setSearchTerm(e.target.value)} autocomplete="off"
                                                placeholder="Wallet Address" name="search-Id" />
                                        </th>
                                        <th>
                                            <input type="text" class="form-control" onChange={(e) => setUserAddress(e.target.value)} autocomplete="off"
                                                placeholder="Referral Code" name="search-mail" />
                                        </th>
                                        <th>
                                            <input type="text" class="form-control" autocomplete="off"
                                                placeholder="Date" name="search-sourceAmount" />
                                        </th>
                                        <th>
                                            <input type="text" class="form-control" autocomplete="off"
                                                placeholder="Referred User" name="search-sourceAmount" />
                                        </th>
                                       
                                    </tr>
                                </tfoot>
                               
                            </table>




                            
                        </div>
                      
                    </div>
                  

                  
                </div>
               
            </div>
            

        </div>
       </div>
              </section>
              <Footer></Footer>

</div>
          
    </div>
  )
}

export default UserWallet