import React, {useState} from 'react';
import Footer from './Footer';
import { useAdminListQuery, useDeleteUserMutation } from './store/ReduxApis';
import { Link } from 'react-router-dom';
import DeleteUser from './DeleteUser';

function AdminList() {

  
    const adminList = useAdminListQuery();

    console.log(adminList);

  return (
      <div>
          <div class="content-wrapper">
   
    <section class="content-header">
        <div class="container-fluid">
           
            <div class="row mb-2">
                <div class="col-sm-6">
                   <h5>Add List</h5>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right text">
                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                        <li class="breadcrumb-item active">Admin List</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
    

    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="card-title"></h3>
                                      <div class="box-tools cards">
                                          <h3 style={{ fontSize: "19px" }}>Admin List</h3>
                                          <Link to="/content/adduser">
                                          <button type="button" class="btn btn-primary pull-right addAdmin">
                                    <i class="fa fa-plus"></i> &nbsp; Add Admin
                                </button>
                                          </Link>
                               
                            </div>
                        </div>

                        
                        <div class="card-body">
                        <div class="table-responsive"> 
                            <table id="example" class="table table-bordered table-striped table-hover" datatable
                               >
                                <thead>
                                    <tr className='tr'>
                                       
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                              
                                    </tr>
                                </thead>

                                <tbody>

                                                  {adminList && adminList?.data?.message.map(e => (
                                        <tr className='tr'>
                                                         
                                                          <td>{e.firstName}</td>
                                                          <td>{e.lastName}</td>

                                                          <td>{e.email}</td>
                                   
                                    </tr>
                                   ))}
                                </tbody>
                             

                            </table>
                           </div>
                        </div>
                       
                    </div>
                   

                   
                </div>
              
            </div>
         

        </div>
      
    </section>
    <Footer></Footer>
</div>
    </div>
  )
}

export default AdminList