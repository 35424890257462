import React, { useState, useEffect } from 'react'

import '../../assets/main.css';
import '../../assets/admin.css';

import Logo from '../../assets/logos/logo.png'


import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAddMarketWalletMutation, useGlobalSettngsQuery, useMarketAddRevokeMutation, useOperationAddRevokeMutation } from "../store/ReduxApis"
import Footer from "../Footer"
import {
    toast , Toaster
} from 'react-hot-toast';






function WalletTransactions() {

    const token = useGlobalSettngsQuery();
    const [account, setAccount] = useState("")
    const [amount, setAmount] = useState("")
    const [startTime, setStartTime] = useState("")

    const [value, onChange] = useState(new Date());
    const [loading, setLoading] = useState(false)
    
    const [createPost, responseInfo] = useOperationAddRevokeMutation();

    console.log(responseInfo);

   
    


    const min = token?.data?.data[0]?.min_contribution;
    const max = token?.data?.data[0]?.max_contribution;
    const bnb = token?.data?.data[0]?.referralBonus
    
    console.log(value)

    const timestamp = Date.parse(value);
    console.log(timestamp)

    console.log(responseInfo)

    const newPost = {
       
         
        "type": "airdrop",
      "accounts": [
        account
    ]
    }

    const addWallet = (e) => {
        e.preventDefault()
        setLoading(true)
        createPost(newPost)
    }

    useEffect(() => {
        if (responseInfo?.data?.status === "success") {
            toast("Succefully Added Airdrop Revoke")  
            setLoading(false)
        } else if(responseInfo?.error?.data?.error === "Returned error: execution reverted: Airdrop  : user claimed all funds or user may not be added") {
            toast("User claimed all funds or user may not be added")  
            setLoading(false)
        } else if (responseInfo?.data?.status === "fail") {
            toast(`Token not added`)  
            setLoading(false)
        } else if (responseInfo?.error?.status === 500) {
            toast(`Please Try Valid Ethereum address`)  
            setLoading(false)
        } else if (responseInfo?.error?.data?.status === "fail") {
            toast(`${responseInfo?.error?.data?.message}`)  
            setLoading(false)
        } 
      
    }, [responseInfo])
    
    

  return (
      <div>
          <Toaster />
    <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
          <div class="row mb-2">
              <div class="col-sm-6 text">
                  <h1>Add Airdrop Revoke Distribution Wallet</h1>
              </div>
              <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item"><a href="#">Home</a></li>
                      <li class="breadcrumb-item active">Airdrop Revoke Distribution Wallet</li>
                  </ol>
              </div>
          </div>
      </div>
  </section>
<div class="row">
  <div class="col-md-8 offset-2">
      <div class="card  card-primary">
          <div class="card-header">
              <h3 class="card-title">Add Airdrop Revoke Distribution Wallet</h3>
          </div>
          <form>
              <div class="card-body">
                  <div class="form-group">
                      <label className='label'> Account :</label>
                      <input  type="text" formControlName="network" onChange={(e) => setAccount(e.target.value)}
                          class="form-control input" />
  
                  </div>
              </div>
              
              <div class="card-footer">
                <button type="submit"  onClick={(e) => addWallet(e)}  className={loading === true ? "changed" : "changedColor"}
                    style={{height: "34px", width:"100px"}}>Submit</button>
            </div>
          </form>
      </div>
  </div>
</div>
<Footer></Footer>
</div>
</div>
   
  )
}

export default WalletTransactions;